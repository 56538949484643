
import Pagination from "../Pagination"
import moment from 'moment-timezone';



export default function Table({ header, data, filters, paginate, limitation, questions, handleOnShowResponse }) {
    console.log('header', header)
    return (
        <div className="relative overflow-x-auto  rounded-lg border  shadow-sm py-6">
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">
                <TableHeader header={header} />
                <TableBody header={header} data={data} questions={questions} handleOnShowResponse={handleOnShowResponse} />
            </table>


            <Pagination total={data.total} limit={filters.limit} paginate={paginate} limitation={limitation} />

        </div>
    )
}


const TableHeader = ({ header }) => {

    return (
        <thead className="text-xs text-primary uppercase  h-12 ">
            <tr className="border-b border-gray-200   font-inter text-center">
                <th scope="col" className="font-semibold px-6 py-3 w-10 " > #</th>
                {header.map((item, index) => {
                    return (
                        item === "ملاحظات العميل" ?
                            <td key={index} className="py-3  whitespace-nowrap px-40">{item}</td>
                            :
                            <td key={index} className="px-6 py-3  whitespace-nowrap" >{item}</td>
                    )
                })}

            </tr>
        </thead>
    )
}

const TableBody = ({ header, data, questions, handleOnShowResponse }) => {


    const handleVoiceAgentLabelField = (row, col) => {
        let value = row[col]
        if (row) {
            row.use_case_id.fields.map((field) => {
                if (col === field.label) {
                    value = row[field.name]
                }
            })
        }

        if (col === "created_on") {
            value = moment(row[col]).tz("Asia/Amman").format('YYYY-MM-DD HH:mm:ss')
        }

        if (col === "ملاحظات العميل") {
            value = (
                row[col] ?
                    <div onClick={() => handleOnShowResponse(row[col])} className={`h-24 overflow-y-auto flex justify-center items-center ${col === "ملاحظات العميل" && row[col] ? "cursor-pointer" : undefined}`}>
                        {row[col]}
                    </div> : undefined)
        }

        return value ? value : "-"
    }

    return (
        <tbody>
            {data.data.map((row, rowIndex) => {
                return (
                    <tr key={rowIndex} className={`${rowIndex >= data.length - 1 ? undefined : 'border-b border-gray-200'} font-inter h-20 text-center`}>
                        <th scope="col" className="font-semibold px-6 py-3 w-10 " >{rowIndex + 1}</th>
                        {header.map((col, colIndex) => (
                            questions.includes(col) ?
                                <td key={colIndex} className=" px-6 py-3">
                                    {handleVoiceAgentLabelField(row, col)}

                                </td>
                                :
                                <td key={colIndex} className="px-6 py-3 align-middle" >{handleVoiceAgentLabelField(row, col)}</td>

                        ))}
                    </tr>
                )
            })}
        </tbody>
    )
}

