import axios from "axios"
import { BASE_URL } from "../../constants/config"


export const login = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/organization/login`, data, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const readOne = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/organization/read/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('responsereadone', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const read = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/organization/`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const update = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/organization/${id}`, body, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const updateWorkspaces = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/organization/${id}/workspaces`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const editWorkspace = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/organization/${id}/workspace`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const removeWorkspace = async (id, name) => {
    try {

        const response = await axios.delete(`${BASE_URL}/organization/${id}/workspace/${name}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)

        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const create = async (body) => {
    try {
        const response = await axios.post(`${BASE_URL}/organization/`, body, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


export const deleteOne = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/organization/delete/${id}`, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}

export const deactivate = async (id, body) => {
    try {
        const response = await axios.put(`${BASE_URL}/organization/deactivate/${id}`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('response', response)
        if (response.status === 200) {
            return response.data.data
        }
        return response
    } catch (error) {
        console.log('error', error)
        throw (error.response.data)
    }
}


// if(error.response.status === 401 && error.response.statusText='Unauthorized')