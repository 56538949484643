import { useEffect, useRef, useState } from "react"
import { deactivate, deleteOne, read, update } from "../../../services/apis/organization"
import Loading from "../../../components/Loading"
import Layout from "../Layout"
import ConfirmModal from "../../../components/DeleteModal"
import TextInput from "../../../components/inputs/TextInput"

import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { NoSymbolIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"
import ModifyOrganizationDialog from "../../../components/dialogs/ModifyOrganizationDialog"


const OrganizationItem = ({ organization, setActivate, setSelected, setOpenDeactivateModal, setOpenDeleteModal, setOpenModificationModal, setProfile }) => {

    const onRemove = (id) => {
        setSelected(id)
        setOpenDeleteModal(true)

    }

    const onDeactivate = (id, flag) => {
        setActivate(flag)
        setSelected(id)
        setOpenDeactivateModal(true)
    }

    const onModify = (id, profile) => {
        setSelected(id)
        setProfile({ name: profile.name, conversation_ai_platform: profile.conversation_ai_platform, environment: profile.environment, logo: profile.logo })
        setOpenModificationModal(true)
    }


    return (
        <tr className={`${organization.deactivate && 'bg-gray-300'}`}>
            <td class="p-4 border-b border-blue-gray-50">
                <div class="flex items-center gap-3">
                    <img src={organization.logo ? organization.logo : "https://zradia.s3.us-east-2.amazonaws.com/organizations/5825138337"}
                        alt="organization logo" class="relative inline-block h-16 w-16 border p-1 !rounded-full object-contain" />

                    <div class="flex flex-col">

                        <>

                            <p class="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900 text-start">
                                {organization.name}
                            </p>
                            <p
                                class="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900 opacity-70">
                                {organization.email}
                            </p>
                        </>

                    </div>
                </div>
            </td>





            <td class="p-4 border-b border-blue-gray-50">


                <p class="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                    {organization.environment.charAt(0).toUpperCase() + organization.environment.slice(1)}
                </p>

            </td>

            <td class="p-4 border-b border-blue-gray-50">

                <p class="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                    {organization.conversation_ai_platform}
                </p>

            </td>

            <td class="p-4 border-b border-blue-gray-50">
                <p class="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                    {organization.created_on}
                </p>
            </td>

            <td class="p-4 border-b border-blue-gray-50">
                <div className="flex space-x-4 justify-center bg-gray-100 rounded-xl py-1 shadow-sm">
                    <PencilSquareIcon className="h-7 w-7 text-primary cursor-pointer border-r-2  pr-2" onClick={() => onModify(organization._id, organization)} />
                    <NoSymbolIcon className="h-7 w-7 text-gray-600 cursor-pointer border-r-2 pr-2" onClick={() => !organization.deactivate ? onDeactivate(organization._id, false) : onDeactivate(organization._id, true)} />
                    <TrashIcon className="text-red-600 h-7 w-7 mx-auto cursor-pointer pr-2" onClick={() => onRemove(organization._id)} />
                </div>
            </td>
            {/* <td class="p-4 border-b border-blue-gray-50">
                {!organization.deactivate ?
                    <button className="flex bg-red-400 items-center text-white px-2 py-1 rounded-lg shadow-lg " onClick={() => onDeactivate(organization._id, false)}>Deactivate</button>
                    :
                    <button className="flex bg-primary items-center text-white  px-2 py-1  rounded-lg shadow-lg " onClick={() => onDeactivate(organization._id, true)} >Activate </button>

                }
            </td>



            <td class="p-4 border-b border-blue-gray-50">
                <button className="flex bg-red-400 items-center text-white  px-2 py-1  rounded-lg shadow-lg " onClick={() => onRemove(organization._id)} >Delete</button>
            </td> */}




            <td class="p-4 border-b border-blue-gray-50">

            </td>
        </tr >
    )
}


export default function Organizations() {
    const [organizations, setOrganizations] = useState([])
    const [loading, setLoading] = useState(false)
    const [trigger, setTrigger] = useState(0)
    const [openDeactivateModal, setOpenDeactivateModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openModificationModal, setOpenModificationModal] = useState(false)
    const [selected, setSelected] = useState('')
    const [activate, setActivate] = useState(false)


    const [profile, setProfile] = useState({ name: '', conversation_ai_platform: '', environment: 'development', logo: '' })
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setLoading(true)
        read().then((response) => {
            console.log('response', response)
            setOrganizations(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })
    }, [trigger])


    const handleOnRemove = async (id) => {
        setLoading(true)
        deleteOne(id).then((response) => {
            setTrigger(previous => previous + 1)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }


    const handleOnDeactivate = async (id, body) => {
        setLoading(true)
        deactivate(id, body).then((response) => {
            console.log('redf')
            setTrigger(previous => previous + 1)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }


    const handleOnUpdate = async () => {
        console.log('profileupdated', profile)
        const formData = new FormData()
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        formData.set("name", profile.name)
        formData.set("conversation_ai_platform", profile.conversation_ai_platform)
        const data = Object.fromEntries(formData.entries())

        setLoading(true)
        update(selected, data).then((response) => {
            setTrigger(previous => previous + 1)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })
    }




    if (loading) {
        return (
            <Layout title="Organizations">
                <Loading />
            </Layout>
        )
    }

    return (
        <Layout title="Organizations">



            <div class="p-10  bg-white border rounded-lg shadow-sm">
                <table class="w-full mt-4 text-left table-auto min-w-max text-center">
                    <thead>
                        <tr>
                            <th class="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50 text-start">
                                <p class="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                                    Organization
                                </p>
                            </th>

                            <th class="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50">
                                <p class="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                                    Environment
                                </p>
                            </th>

                            <th class="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50">
                                <p class="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                                    Conversation AI Platform
                                </p>
                            </th>

                            <th class="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50">
                                <p class="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                                    Created Date
                                </p>
                            </th>


                            <th class="p-4 border-y border-blue-gray-100 bg-blue-gray-50/50">
                                <p class="block font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                                    Actions
                                </p>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {organizations.map((organization) => {
                            return (
                                <OrganizationItem key={organization._id} organization={organization} setProfile={setProfile} setOpenModificationModal={setOpenModificationModal} setActivate={setActivate} setSelected={setSelected} setOpenDeleteModal={setOpenDeleteModal} setOpenDeactivateModal={setOpenDeactivateModal} />
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <ModifyOrganizationDialog eventFunction={() => handleOnUpdate()} isOpen={openModificationModal} selectedFile={selectedFile} setSelectedFile={setSelectedFile} setIsOpen={setOpenModificationModal} profile={profile} setProfile={setProfile} />
            <ConfirmDialog eventFunction={() => { handleOnRemove(selected); }} isOpen={openDeleteModal} setIsOpen={setOpenDeleteModal} title="Delete Organization Account" description="Are you sure you want to delete the organization?" />
            <ConfirmDialog eventFunction={() => handleOnDeactivate(selected, activate ? { deactivate: null } : { deactivate: new Date() })} isOpen={openDeactivateModal} setIsOpen={setOpenDeactivateModal} title={`${activate ? 'Activate' : 'Deactivate'} Organization Account`} description={`Are you sure you want to ${!activate ? 'deactivate' : 'activate'} the organization?`} />
        </Layout>

    )
}


