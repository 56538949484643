import moment from "moment"
import Pagination from "./Pagination"
import { useState } from "react";




export default function PendingCallsTable({ calls, filters, paginate, allChecked, selectedRows, handleSelectAll, handleSelectRow, limitation }) {


    return (
        <div className="relative overflow-x-auto  rounded-xl border  shadow-sm py-6">
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">

                <thead className="text-xs text-primary uppercase  h-12 ">
                    <tr className="border-b border-gray-200   font-inter text-center">
                        <th scope="col" className="px-6 py-3" >
                            <input
                                type="checkbox"
                                checked={allChecked}
                                onChange={handleSelectAll}
                                className="form-checkbox text-primary   "
                            />
                        </th>

                        <th scope="col" className="px-6 py-3" >
                            session id
                        </th>


                        <th scope="col" className="px-6 py-3" >
                            Sub Status
                        </th>

                        <th scope="col" className="px-6 py-3" >
                            Target
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            Name
                        </th>

                        <th scope="col" className="px-6 py-3" >
                            voice agent
                        </th>
                        <th scope="col" className="px-6 py-3" >
                            date
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {calls.data.map((call) => {
                        return (<TableItem key={call.sid} call={call} handleSelectRow={handleSelectRow} selectedRows={selectedRows}></TableItem>)
                    })}
                </tbody>
            </table>
            <Pagination total={calls.total} limit={filters.limit} paginate={paginate} limitation={limitation} />
        </div>
    )
}

const TableItem = ({ call, handleSelectRow, selectedRows }) => {

    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">
            <td className="">
                <input
                    type="checkbox"
                    checked={selectedRows.includes(call._id)}
                    onChange={() => handleSelectRow(call._id)}
                    className="form-checkbox text-primary"
                />
            </td>
            <td className="px-6 py-4 text-gray-600">
                {call.sid}
            </td>

            <td className="px-6 py-4 text-gray-600">
                {call.subStatus}
            </td>

            <td className="px-6 py-4 text-gray-600">
                {call.metadata ? call.metadata.target ? call.metadata.target : undefined : undefined}
            </td>

            <td className="px-6 py-4 text-gray-600">
                {call.metadata ? call.metadata.name ? call.metadata.name : undefined : undefined}
            </td>

            <td className="px-6 py-4">
                {call.use_case_id.name}
            </td>
            <td className="px-6 py-4 text-gray-600">
                {moment(call.created_on).format('DD-MM-YYYY')}
            </td>

        </tr>

    )
}