import { useState } from "react";
import TextInput from "../../components/inputs/TextInput";
import WorkspacesTable from "./WorkspacesTable";
import { editWorkspace, removeWorkspace, update, updateWorkspaces } from "../../services/apis/organization";
import Loading from "../../components/Loading";
import { Slide, toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import WorkspaceDialog from "./WorkspaceDialog";


export default function Workspaces({ organization, setTrigger, profile }) {
    const [workspace, setWorkspace] = useState({ workspace_name: '', botId: '', botEndpoint: '', jwt_token: '', arabic_caller: '', english_caller: '' })
    const [loading, setLoading] = useState(false)

    const [selectedWorkspace, setSelectedWorkspace] = useState('')
    const [selectedWorkspaceProfile, setSelectedWorkspaceProfile] = useState({ workspace_name: '', botId: '', botEndpoint: '', jwt_token: '', arabic_caller: '', english_caller: '' })
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false)



    const handleOnInputChange = (event) => {
        setWorkspace((previous) => {
            return {
                ...previous,
                [event.target.name]: event.target.value
            }
        })
    }

    const checkInputs = () => {
        for (const key in workspace) {
            if (workspace[key].trim() === '') {
                toast.error("Failed ! Please fill in all the fields", { transition: Slide })
                return false;
            }
        }
        return true;
    };

    const handleOnAddWorkspace = async () => {
        if (checkInputs()) {
            if (organization.workspaces.some((item) => item.workspace_name === workspace.workspace_name)) {
                toast.error("Failed ! This workspace name already exists", { transition: Slide })
                return
            }
            setLoading(true)
            const workspaces = [...organization.workspaces, workspace]
            console.log('workspapces', workspaces)
            updateWorkspaces(profile.organization._id, { "workspaces": workspaces }).then((response) => {
                setLoading(false)
                setTrigger((previous) => previous + 1)
            }).catch((error) => {
                setLoading(false)
                console.log('error', error)
            })
        }
    }


    const handleOnRemoveWorkspace = async (selected) => {
        setLoading(true)
        const workspace = organization.workspaces.find((item, index) => selected === index)
        console.log('workspace', workspace)
        removeWorkspace(profile.organization._id, workspace.workspace_name).then((response) => {
            setLoading(false)
            setTrigger((previous) => previous + 1)
        }).catch((error) => {
            setLoading(false)
            toast.error(error.messsage, { transition: Slide })
        })
    }

    const handleOnEditWorkspace = (selected, workspace) => {
        setLoading(true)
        editWorkspace(profile.organization._id, workspace).then((response) => {
            setLoading(false)
            setTrigger((previous) => previous + 1)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })

    }


    // const handleOnEditWorkspace = () => {

    // }

    // const handleOnRemoveWorkspace = () => {

    // }

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="px-12 pt-4">
                <p className="text-xl font-inter text-secondary font-semibold mb-2">Manage Workspaces</p>
                <p className="text-md font-inter text-gray-400 font-medium leading-normal mb-5 w-full lg:w-3/4">Manage your workspaces across bot environments, endpoints, and callers in both English and Arabic.</p>


                <div className="w-full md:w-1/4">
                    <TextInput label='Name' placeholder='Workspace Name' name='workspace_name' id='workspace_name' onChange={handleOnInputChange} />
                </div>
                <div className="flex flex-wrap mb-5 ">

                    <div className="w-full md:w-2/5 mr-4 ">
                        <TextInput label='Bot Id' placeholder='st-366d7598-7a05-578e-a1a0-dabee8506c9b' name='botId' id='botId' onChange={handleOnInputChange} />
                    </div>
                    <div className="w-full md:w-2/5">
                        <TextInput label='JWT Token' placeholder='XXXXXXXXXXXXXXXXXXXXXXX' name='jwt_token' id='jwt_token' onChange={handleOnInputChange} />
                    </div>
                    <div className="w-full md:w-4/5">
                        <TextInput label='Bot Endpoint' placeholder='https://sl-smartassist.labeah.ai/' name='botEndpoint' id='botEndpoint' onChange={handleOnInputChange} />
                    </div>
                    <div className="w-full md:w-1/4 mr-4">
                        <TextInput label='English Caller' placeholder='83XXXX' name='english_caller' id='english_caller' onChange={handleOnInputChange} />
                    </div>
                    <div className="w-full md:w-1/4">
                        <TextInput label='Arabic Caller' placeholder='83XXXXX' name='arabic_caller' id='arabic_caller' onChange={handleOnInputChange} />
                    </div>
                </div>

                <div className="flex justify-between">
                    <button className="text-primary bg-gray-200 hover:bg-gray-300 py-1 px-6 rounded-lg shadow-sm transition duration-300 ease-in-out " onClick={() => handleOnAddWorkspace()}>+ Add Workspace</button>
                </div>

            </div>


            <div className="px-12 pt-4">
                <p className="text-xl font-inter text-secondary font-semibold my-4">Workspaces</p>
                <WorkspacesTable workspaces={organization.workspaces || []} setOpenDeleteDialog={setOpenDeleteDialog} setSelectedWorkspace={setSelectedWorkspace} setSelectedWorkspaceProfile={setSelectedWorkspaceProfile} setOpenUpdateDialog={setOpenUpdateDialog} />
            </div>

            <ConfirmDialog eventFunction={() => { handleOnRemoveWorkspace(selectedWorkspace); }} isOpen={openDeleteDialog} setIsOpen={setOpenDeleteDialog} title="Delete Workspace" description="Are you sure you want to delete this workspace?" />
            <WorkspaceDialog eventFunction={() => { handleOnEditWorkspace(selectedWorkspace, selectedWorkspaceProfile); }} isOpen={openUpdateDialog} setIsOpen={setOpenUpdateDialog} setSelectedWorkspaceProfile={setSelectedWorkspaceProfile} selectedWorkspaceProfile={selectedWorkspaceProfile} />

        </>
    )
}

//https://sl-smartassist.labeah.ai/api/1.1/public/bot/st-366d7598-7a05-578e-a1a0-dabee8506c9b/smartassist/dialout
//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImNzLTE1Y2UzZWJkLTNiMGEtNWQwZC1hMWM3LWQ2Mzk3NDBlNzU3OCJ9.HA-MwRpfBQM0cviqIqdWbae77-ZAXr9_PE5fbA5IqJI
//8396001
