


import { EyeIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { formatDateDDMMYY } from "../../../utils/formatting";
import moment from 'moment-timezone';

const types = { "calls": "Calls", "batches": "Batches", "orderRequests": "Order Requests", "conversations": "Conversations" }

export default function ReportCard2({ report, handleOnRemove, handleOnViewReport, handleOnUpdate }) {

    return (
        <div className="max-w-[493px] w-full h-[493px] p-6 bg-white rounded-lg shadow-lg relative  flex flex-col rounded-xl border">
            <div className="w-full break-words mb-4 ">
                <h4 className="text-lg font-semibold text-secondary">{report.name}</h4>
                <div className="flex space-x-2 my-1">
                    <><span className="text-secondary font-semibold">Type</span> <span className="text-sm font-semibold px-2 py-1  rounded-lg bg-input mr-6">{types[report.type]}</span></>
                    {(report.type === "calls" || report.type === "conversations") && <><span className="text-secondary font-semibold">status</span> <span className="text-sm font-semibold px-2 py-1  rounded-lg bg-active ">{report.subStatus ? report.subStatus : '∀ statuses'}</span></>}
                </div>

            </div>
            <hr />
            <p className="text-gray-600 mb-4 grow line-clamp-5 mt-2">{report.description}</p>
            <hr />
            <div className="mb-2 rounded-lg p-2 mt-2">
                {report.start_date && report.end_date &&
                    <div className="flex space-x-2">
                        <span className="px-3 py-1 text-sm font-medium bg-primary text-white rounded-full font-inter">
                            {formatDateDDMMYY(report.start_date)} To {formatDateDDMMYY(report.end_date)}
                        </span>
                    </div>
                }

                <div className="flex flex-wrap gap-2 mt-2">
                    {report.lastN && <span className="px-3 py-1 text-xs font-semibold bg-primary text-white rounded-full font-inter"> {`Last ${report.lastN} Days`}</span>}
                    <span className="px-3 py-1  font-semibold bg-primary text-white rounded-full font-inter text-xs">{report.usecase ? report.usecase.name : '∀ voice agents'}</span>

                    {report.type === "calls" &&
                        <span className="px-3 py-1 text-xs font-semibold bg-primary text-white rounded-full font-inter">{report.subStatus ? report.subStatus : '∀ statuses'}</span>
                    }
                    {report.type === "conversations" &&
                        <span className="px-3 py-1 text-xs font-semibold bg-primary text-white rounded-full font-inter">{report.checkPoint ? report.checkPoint : '∀ statuses'}</span>
                    }

                    {!report.start_date && !report.lastN && <span className="px-3 py-1 text-xs font-semibold bg-primary text-white rounded-full font-inter">∀ time</span>}
                </div>

            </div>

            <div className="flex">
                <span className="text-gray-400 mb-2">{moment(report.created_on).tz("Asia/Amman").format('YYYY-MM-DD')}</span>
                <div className="ml-auto flex space-x-3 bg-gray-100 mr-5 p-0 items-center justify-center border px-5 rounded-xl shadow-lg border-gray-200" >
                    <TrashIcon className="w-6 h-6 text-red-500 cursor-pointer m-0 border-r border-gray-200" onClick={() => handleOnRemove(report._id)} />
                    <PencilSquareIcon className="w-6 h-6 text-gray-500 cursor-pointer m-0 border-r border-gray-200" onClick={() => handleOnUpdate(report._id)} />
                    <EyeIcon className="w-6 h-6 text-primary cursor-pointer m-0" onClick={() => handleOnViewReport(report._id)} />
                </div>
            </div>

        </div>
    )
}