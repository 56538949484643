import { PlusCircleIcon } from "@heroicons/react/24/outline";

function MultiTextInput({
  originalInputs, // to take a clone from the inputs that are being sent.
  inputsData, //handle the creation of multiple inputs
  setinputsData,
  data, //handle the data being sent from the inputs
  setdata,
  testingList = []
}) {
  const handleInputChange = (index, fieldName, value) => {
    console.log(index, fieldName, value)
    const newData = data;
    if (newData[index]) {
      newData[index][fieldName] = value;
    } else {
      newData[index] = {};
      newData[index][fieldName] = value;
    }
    setdata(newData);
  };

  const handleAddAlt = (e) => {
    e.preventDefault();
    setinputsData([...inputsData, originalInputs]);
  };

  return (
    <div>
      {inputsData.map((data, index) => (
        <div key={index} className="grid grid-cols-1 md:grid-cols-3 gap-5 lg:flex lg:space-x-3 mb-5">
          {/* className="flex space-x-3 mb-5" */}
          {/* grid grid-cols-3 gap-5 lg:flex lg:space-x-3 mb-5 */}
          {data.map((el, idx) => {
            return (
              <div key={idx}>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  {el.label}
                </label>
                {el.name === "target" ?
                  <div>
                    <select
                      name={el.name}
                      onChange={(e) =>
                        handleInputChange(index, el.name, e.target.value)
                      }
                      className="block px-3 rounded-md border border-gray-300  text-gray-900 shadow-sm py-2.5"
                    >
                      <option value="select" key="select" >select</option>
                      {testingList.map((option, index) => {
                        return (
                          <option key={index} value={option.target}>{option.target} {option.metadata && `(${option.metadata})`}</option>
                        )
                      })}
                    </select>
                  </div>
                  :
                  el.type === "bool" ? (
                    <div>
                      <select
                        defaultValue={''}
                        name={el.name}
                        onChange={(e) =>
                          handleInputChange(index, el.name, e.target.value)
                        }
                        className="block px-3 rounded-md border border-gray-300  text-gray-900 shadow-sm py-2.5"
                      >
                        <option value={''} disabled>select</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    </div>
                  ) : (
                    <input
                      type={el.type}
                      placeholder={el.placeholder}
                      name={el.name}
                      className="block px-3 rounded-md border border-gray-300  text-gray-900 shadow-sm py-2"
                      onChange={(e) =>
                        handleInputChange(index, el.name, e.target.value)
                      }
                    />
                  )
                }
              </div>
            );
          })}
          <div className="flex space-x-2 my-auto lg:pt-4">
            <PlusCircleIcon
              onClick={(e) => handleAddAlt(e)}
              className="h-5 w-5 my-auto text-green-700 cursor-pointer"
            />
            <span
              onClick={(e) => handleAddAlt(e)} className="block md:block lg:hidden text-green-700">Add More</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MultiTextInput;
