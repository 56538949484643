
// Function to determine sidebar links upon user role
export const getNavigation = (role) => {
    let pages = [
        {
            title: 'Organizations',
            icon: '/icons/Category.png',
            link: '/admin/organizations'
        },
        {
            title: 'Add Organization',
            icon: '/icons/Manager.png',
            link: '/admin/add_organization'
        }
    ]
    return pages
}

