
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { login } from "../services/apis/organization"
import { AuthContext } from "../store/auth_context";

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { signIn } = useContext(AuthContext)

    const handleOnLogin = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData.entries())
        console.log('data', data)
        login(data).then((response) => {
            signIn(response)
            if (response.role === 'tester') {
                navigate("/use_cases")
            } else {
                navigate("/dashboard");
            }
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }

    return (
        <div class="min-h-screen flex items-center justify-center bg-default">
            <div class="w-full max-w-5xl  overflow-hidden grid grid-cols-1 md:grid-cols-2 py-12 ">
                <div class="p-8 flex flex-col justify-center rounded-lg shadow-lg bg-white h-full py-20 px-10">

                    <h4 className="text-secondary leading-10 text-3xl font-bold">Welcome to OCM</h4>
                    <h6 className="text-coal leading-10 text-2xl font-bold">Login</h6>

                    {process.env.REACT_APP_ENVIRONMENT !== "production" &&

                        <p className=" text-red-500 font-semibold py-1 rounded-lg leading-10">Dev Environment</p>
                    }
                    <form class="mt-4" onSubmit={handleOnLogin}>
                        <div class="mb-4">
                            <label class="block text-gray-500 mb-2">workspace id</label>
                            <input type="text" name='workspace_id' class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:primary" placeholder="workspace id" />
                        </div>
                        <div class="mb-4">
                            <label class="block text-gray-500 mb-2">username</label>
                            <input type="text" name='email' onChange={(e) => { setEmail(e.target.value) }} value={email} class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:primary" placeholder="Enter your email address" />
                        </div>
                        <div class="mb-4">
                            <label class="block text-gray-500 mb-2">password</label>
                            <input type="password" name='password' onChange={(e) => { setPassword(e.target.value) }} value={password} class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:primary" placeholder="Create your password" />
                        </div>

                        <button class="w-full bg-primary text-white py-2 rounded-lg hover:bg-secondary transition duration-300" type="submit">
                            Login
                        </button>
                    </form>
                </div>

                <div class="hidden md:block bg-transparent">
                    <img class="w-3/4 h-full object-contain mx-auto" src="Illustration.png" alt="..." />
                </div>

                <ToastContainer />
            </div>
        </div>
    )
}

//backup
/**
 *    localStorage.setItem("organization_id", response.organization._id);
            localStorage.setItem("user", true);
            localStorage.setItem("userData", JSON.stringify({ _id: response._id, email_address: response.email, role: response.role, name: response.name }));
            localStorage.setItem("email_address", email.toLocaleLowerCase().toString().trim());
 */