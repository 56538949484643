import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { getAllPendingCallsAPI, } from "../services/apis/dashboard";
import { AuthContext } from "../store/auth_context";
import NoData from "../components/NoData";
import PendingCallsTable from "../components/PendingCallsTable";

import { Slide, toast, ToastContainer } from "react-toastify";
import DeleteModal from "../components/DeleteModal";
import { ReadPendingCalls, updateMany } from "../services/apis/calls";
import { constructQueryParams } from "../utils/encodeURI";
import Loading from "../components/Loading";

export default function PendingCalls() {
  const { profile } = useContext(AuthContext)
  const [calls, setCalls] = useState({ data: [], total: 0, count: 0 })
  const [filters, setFilters] = useState({ limit: 10, page: 1 })
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenExecute, setIsOpenExecute] = useState(false)
  const [trigger, setTrigger] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const allChecked = selectedRows.length === calls.length;


  const handleSelectAll = () => {
    setSelectedRows(allChecked ? [] : calls.data.map((item) => item._id));
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleOnSubmit = async (event) => {
    // event.preventDefault()
    // const formData = new FormData(event.target)
    // const data = Object.fromEntries(formData.entries())
    // console.log('data', data)
    updateMany({ 'calls': selectedRows, is_pending: false, subStatus: "IDLE", status: "IN_QUEUE" }).then((response) => {
      toast.success("Execute Pending Calls Successfully!", { transition: Slide })
      setTrigger(prev => prev + 1)
    }).catch((error) => {
      toast.error(error.message, { transition: Slide })
    })
  }
  const paginate = (page) => {
    setFilters((previous) => { return { ...previous, page: page } })
  }

  const limitation = (limit) => {
    setFilters((previous) => { return { ...previous, limit: limit } })
  }

  const handleOnCancel = async (event) => {

    console.log('calls', selectedRows)
    updateMany({ 'calls': selectedRows, is_cancelled: new Date() }).then((response) => {
      toast.success("Calls Cancelled Successfully!", { transition: Slide })
      setTrigger(prev => prev + 1)
    }).catch((error) => {
      toast.error(error.message, { transition: Slide })
    })
  }


  useEffect(() => {
    setLoading(true)
    const query = constructQueryParams(filters);
    ReadPendingCalls(profile.organization._id, query).then((response) => {
      console.log('response', response)
      setLoading(false)
      setCalls((previous) => {
        return ({
          ...previous,
          data: response.calls,
          total: response.total,
          count: response.count
        })
      })
    }).catch((error) => {
      setLoading(false)
      console.log(error);
    });
  }, [profile.organization._id, filters, trigger]);


  if (loading) {
    return (
      <Layout title='Reports'>
        <Loading />
      </Layout>
    )
  }


  console.log('callslength', calls.data.length)
  return (
    <Layout title='Pending Calls'>

      {profile.role === "monitor" ? undefined :
        selectedRows.length > 0 &&
        <div className="flex space-x-2 float-right mb-5">
          <button className="bg-primary text-white p-2 rounded-lg shadow-lg opacity-80" onClick={() => setIsOpenExecute(true)}>Execute</button>
          <button className="bg-red-400 text-white p-2 rounded-lg shadow-lg opacity-80" onClick={() => setIsOpen(true)}>Cancel</button>
        </div>}

      {/* <PendingDialog isOpen={isOpen} setIsOpen={setIsOpen} /> */}

      {calls.data.length > 0 ?
        <PendingCallsTable calls={calls} filters={filters} paginate={paginate} limitation={limitation} allChecked={allChecked} handleSelectAll={handleSelectAll} selectedRows={selectedRows} handleSelectRow={handleSelectRow} />

        :
        <NoData />
      }

      <DeleteModal open={isOpenExecute} setOpen={setIsOpenExecute} deleteFunc={() => { handleOnSubmit(); }} title="Execute Pending Calls" description="Are you sure you want to execute selected calls?" />

      <DeleteModal open={isOpen} setOpen={setIsOpen} deleteFunc={() => { handleOnCancel(); }} title="Cancel Calls" description="Are you sure you want to cancel selected calls?" />

      <ToastContainer />
    </Layout>
  );
}
