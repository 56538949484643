import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Ticks,
    layouts,
} from 'chart.js';

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const data = [
//     {
//         date: "2024-11-13",
//         statuses: {
//             FAILED: 2,
//             SUCCEED: 5,
//         },
//     },
//     {
//         date: "2024-11-18",
//         statuses: {
//             SUCCEED: 2,
//         },
//     },
// ];

export default function BarChart({ data }) {

    console.log('set', data)

    // Extract labels (dates) and datasets (statuses)
    const labels = data.map((item) => item.date);


    // Accumulate values for each status
    const statusKeys = Array.from(
        new Set(data.flatMap((item) => Object.keys(item.statuses)))
    );

    console.log('statusKeys', statusKeys)

    const datasets = statusKeys.map((status) => ({
        label: status,
        backgroundColor: status === "FAILED" ? "rgba(255, 99, 132, 0.5)" : "rgba(75, 192, 192, 0.5)",
        borderColor: status === "FAILED" ? "rgba(255, 99, 132, 1)" : "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barPercentage: 0.8,
        categoryPercentage: 0.5,
        data: labels.map((date) => {
            const entry = data.find((item) => item.date === date);
            return entry?.statuses[status] || 0; // Default to 0 if no data exists
        }),
    }));


    //Add padding to top
    const values = datasets.map((item) => [...item.data])
    const flattedValues = values.flat()
    const maxValue = Math.max(...flattedValues);
    const suggestedMax = maxValue * 2.2;

    // Chart.js data object
    const chartData = {
        labels,
        datasets,
    };

    // Chart.js options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",

            },
            title: {
                padding: 20,
                display: false,
                text: "Call Analytics Chart",
                font: {
                    size: 18
                }
            },


        },
        layout: {
            padding: 10,
        },
        scales: {
            x: {
                stacked: true, // Enable stacking on the X-axis
            },
            y: {
                stacked: true,
                ticks: {
                    // Force the Y-axis to display only integers
                    callback: function (value) {
                        return Number.isInteger(value) ? value : null;
                    },
                    // stepSize: 1 // Optional: Set a consistent step size
                },
                beginAtZero: true,
                //suggestedMax: suggestedMax
            }
        },
    }

    return <Bar data={chartData} options={options} className='px-5 py-5' />;
};

