import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import TextInput from '../../components/inputs/TextInput'

export default function WorkspaceDialog({ isOpen, setIsOpen, eventFunction, selectedWorkspaceProfile, setSelectedWorkspaceProfile }) {
    const cancel = useRef()
    const [error, setError] = useState('')

    const handleOnInputChange = (event) => {
        setSelectedWorkspaceProfile((previous) => {
            return {
                ...previous,
                [event.target.name]: event.target.value
            }
        })
    }


    const checkInputs = () => {
        for (const key in selectedWorkspaceProfile) {
            if (selectedWorkspaceProfile[key].trim() === '') {
                setError('Failed ! Please fill in all the fields')
                return false;
            }
        }
        setError('')
        return true;
    };


    const handleOnUpdate = () => {
        if (checkInputs()) {
            setIsOpen(false)
            eventFunction()
            setError('')
        }
    }


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)} initialFocus={cancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center ml-24">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-4 flex justify-between">Modify Workspace <span className='text-red-400'>{error}</span></Dialog.Title>

                                <div className="w-full md:w-1/4 relative opacity-50 cursor-not-allowed">
                                    <TextInput label='Name' placeholder='Workspace Name' name='workspace_name' id='workspace_name' value={selectedWorkspaceProfile.workspace_name} onChange={handleOnInputChange} disabled={true} />
                                </div>
                                <div className="flex flex-wrap mb-5 ">

                                    <div className="w-full md:w-2/5 mr-4 ">
                                        <TextInput label='Bot Id' placeholder='st-366d7598-7a05-578e-a1a0-dabee8506c9b' name='botId' id='botId' value={selectedWorkspaceProfile.botId} onChange={handleOnInputChange} />
                                    </div>
                                    <div className="w-full md:w-2/5">
                                        <TextInput label='JWT Token' placeholder='XXXXXXXXXXXXXXXXXXXXXXX' name='jwt_token' id='jwt_token' value={selectedWorkspaceProfile.jwt_token} onChange={handleOnInputChange} />
                                    </div>
                                    <div className="w-full md:w-4/5">
                                        <TextInput label='Bot Endpoint' placeholder='https://sl-smartassist.labeah.ai/' name='botEndpoint' id='botEndpoint' value={selectedWorkspaceProfile.botEndpoint} onChange={handleOnInputChange} />
                                    </div>
                                    <div className="w-full md:w-1/4 mr-4">
                                        <TextInput label='English Caller' placeholder='83XXXX' name='english_caller' id='english_caller' value={selectedWorkspaceProfile.english_caller} onChange={handleOnInputChange} />
                                    </div>
                                    <div className="w-full md:w-1/4">
                                        <TextInput label='Arabic Caller' placeholder='83XXXXX' name='arabic_caller' id='arabic_caller' value={selectedWorkspaceProfile.arabic_caller} onChange={handleOnInputChange} />
                                    </div>
                                </div>

                                <div className="flex justify-end space-x-2">
                                    <button className="text-white bg-red-400 hover:bg-red-500 py-1 px-6 rounded-lg shadow-sm transition duration-300 ease-in-out w-24" onClick={() => {
                                        setIsOpen(false)
                                        setError('')
                                    }}>Cancel</button>
                                    <button className="text-white bg-primary-500 hover:bg-primary-600 py-1 px-6 rounded-lg shadow-sm transition duration-300 ease-in-out w-24" onClick={() => handleOnUpdate()}>Save</button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}