


import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout'
import Details from './Details';
import Workspaces from './Workspaces';
import { readOne, update } from '../../services/apis/organization';
import { AuthContext } from '../../store/auth_context';
import Loading from '../../components/Loading';
import { Slide, toast, ToastContainer } from "react-toastify";

export default function Settings() {
    const { profile, changeLogo } = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState(1)
    const [loading, setLoading] = useState(true)
    const [organization, setOrganization] = useState()
    const [trigger, setTrigger] = useState(0)

    const [selectedImage, setSelectedImage] = useState()

    const handleOnImageChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            setSelectedImage(file);
            setOrganization((previous) => {
                return {
                    ...previous,
                    logo: URL.createObjectURL(file)
                }
            })
        }
    }

    const handleOnUpdateDetails = async () => {
        try {
            setLoading(true)

            const formData = new FormData()
            formData.append('max_concurrent_calls', organization.max_concurrent_calls)

            if (selectedImage) {
                formData.append('file', selectedImage)
            }

            const response = await update(profile.organization._id, formData)
            if (selectedImage) {
                changeLogo(response.logo)
            }
            toast.success("Organization Details Saved Successfully!", { transition: Slide })
            setLoading(false)
        } catch (error) {
            console.log('error', error)
            setLoading(false)
        }

    }

    const handleOnInputChange = (event) => {
        setOrganization((previous) => {
            return {
                ...previous,
                [event.target.name]: event.target.value
            }
        })
    }


    useEffect(() => {
        setLoading(true)
        readOne(profile.organization._id).then((response) => {
            console.log('responsehere', response)
            setOrganization(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }, [profile.organization._id, trigger])

    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    return (
        <Layout title='Settings'>
            <div className="">
                <div className="flex border-b space-x-2 ml-5">
                    <button className={`px-4 py-2 text-sm font-medium font-inter rounded-t-xl transition duration-300 ease-in-out ${activeTab === 1 ? 'text-secondary bg-secondary-100 shadow-inner' : 'text-gray-700 bg-gray-200 hover:bg-gray-300'}`} onClick={() => setActiveTab(1)}>Organization Details</button>
                    <button className={`px-4 py-2 text-sm font-medium font-inter rounded-t-xl transition duration-300 ease-in-out ${activeTab === 2 ? 'text-secondary bg-secondary-100 shadow-inner' : 'text-gray-700 bg-gray-200 hover:bg-gray-300'}`} onClick={() => setActiveTab(2)}>Manage Workspaces</button>
                </div>
                <div className="p-4 bg-white rounded-lg shadow-sm">
                    {activeTab === 1 && <Details organization={organization} handleOnInputChange={handleOnInputChange} handleOnUpdateDetails={handleOnUpdateDetails} handleOnImageChange={handleOnImageChange} />}
                    {activeTab === 2 && <Workspaces organization={organization} setTrigger={setTrigger} profile={profile} />}
                </div>
            </div>
            <ToastContainer />
        </Layout>

    )
}