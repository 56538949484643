

export default function SelectFields({ setData, data, mode, fields, systemfields, questionFields, handleOnCloseCreateModal, handleOnUpdateReport, handleOnCreateReport }) {

    const handleOnChipClick = (label) => {
        setData((previous) => {
            return {
                ...previous,
                fields: previous.fields.includes(label)
                    ? previous.fields.filter((item) => item !== label)
                    : [...previous.fields, label]
            }
        })
    };


    const handleOnChipClickSystemFields = (label) => {
        setData((previous) => {
            return {
                ...previous,
                system_fields: previous.system_fields.includes(label)
                    ? previous.system_fields.filter((item) => item !== label)
                    : [...previous.system_fields, label]
            }
        })
    };


    const handleOnChipClickQuestionFields = (label) => {
        setData((previous) => {
            return {
                ...previous,
                questions_fields: previous.questions_fields.includes(label)
                    ? previous.questions_fields.filter((item) => item !== label)
                    : [...previous.questions_fields, label]
            }
        })
    };

    return (
        <div>

            {(data.type === "calls" || data.type === "conversations") && data.usecase ?
                <>
                    <div className="w-full mb-4">
                        <h5 className="text-lg font-semibold font-inter mb-4">Fields</h5>
                        <div className="flex space-x-2 flex-wrap space-y-1 ">
                            {fields.map((label) => (
                                <CheckboxChip
                                    key={label}
                                    label={label}
                                    isSelected={data.fields.length > 0 ? data.fields.includes(label) : false}
                                    onClick={() => handleOnChipClick(label)}
                                />
                            ))}
                        </div>
                    </div>

                    {questionFields.length > 0 &&
                        <div className="w-full mb-4">
                            <h5 className="text-lg font-semibold font-inter mb-4">Questions Fields</h5>
                            <div className="flex space-x-2 flex-wrap space-y-1 ">
                                {questionFields.map((label) => (
                                    <CheckboxChip
                                        key={label}
                                        label={label}
                                        isSelected={data.questions_fields.length > 0 ? data.questions_fields.includes(label) : false}
                                        onClick={() => handleOnChipClickQuestionFields(label)}
                                    />
                                ))}
                            </div>
                        </div>
                    }
                </>

                :
                undefined
            }

            <div className="w-full mb-4">
                <h5 className="text-lg font-semibold font-inter mb-4">System Fields</h5>
                <div className="flex space-x-2 flex-wrap space-y-2 ">
                    {(data.type === "calls" || data.type === "conversations") ?
                        systemfields.calls_system_fields.map((label) => (
                            <CheckboxChip
                                key={label}
                                label={label}
                                isSelected={data.system_fields.length > 0 ? data.system_fields.includes(label) : false}
                                onClick={() => handleOnChipClickSystemFields(label)}
                            />
                        ))
                        : data.type === "batches" ?
                            systemfields.batches_system_fields.map((label) => (
                                <CheckboxChip
                                    key={label}
                                    label={label}
                                    isSelected={data.system_fields.length > 0 ? data.system_fields.includes(label) : false}
                                    onClick={() => handleOnChipClickSystemFields(label)}
                                />
                            )) :
                            data.type === "orderRequests" ? systemfields.orderRequests_system_fields.map((label) => (
                                <CheckboxChip
                                    key={label}
                                    label={label}
                                    isSelected={data.system_fields.length > 0 ? data.system_fields.includes(label) : false}
                                    onClick={() => handleOnChipClickSystemFields(label)}
                                />
                            )) : undefined}
                </div>
            </div>

            <div className="flex items-center justify-end pt-4 border-t border-solid border-blueGray-200 rounded-b">
                <button onClick={() => handleOnCloseCreateModal()} className="bg-red-500 text-white  font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-lg" type="button">Cancel</button>

                {mode === "update" ?
                    <button onClick={() => handleOnUpdateReport()} className="bg-primary text-white  font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-lg" type="button">Save</button>
                    :
                    <button onClick={() => handleOnCreateReport()} className="bg-primary text-white  font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-lg" type="button">Create</button>
                }
            </div>
        </div>
    );
}

const CheckboxChip = ({ label, isSelected, onClick }) => (
    <div className={`px-4 py-1 rounded-full cursor-pointer m-1  ${isSelected ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'}`} onClick={onClick}>
        {label}
    </div>
);