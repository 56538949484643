
import DatePicker from "react-datepicker";

export default function DatePickerInput({ handleOnDateChange, startDate, endDate }) {
    return (
        <DatePicker
            dateFormat="dd-MM-yyyy"
            onChange={handleOnDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            placeholderText="Date Range"
            className="w-full h-11 px-4 py-2 mt-2 rounded-lg  border-gray-300 
                             focus:ring-1  focus:ring-primary focus:border-primary
                             shadow-sm bg-input placeholder-gray-400 
                             text-gray-900 focus:outline-none transition duration-300 ease-in-out min-w-56" />
    )
}