import SelectInput from "../../../../components/inputs/SelectInput";
import TextInput from "../../../../components/inputs/TextInput";

const types = [
    { text: 'Calls', value: 'calls' },
    { text: 'Job Order', value: 'orderRequests' },
    { text: 'Batches', value: 'batches' },
    { text: 'Conversations', value: 'conversations' }
]

export default function ReportInfo({ data, handleOnInputChange, handleOnChooseReportType, handleOnSelectType }) {

    return (
        <div>

            <div className="flex justify-between">
                <TextInput label="Name" type="text" id="name" name="name" placeholder="Report Name" value={data.name} onChange={handleOnInputChange} />

                {(data.type === "calls" || data.type === "conversations") &&
                    <div className="inline-flex rounded-md  space-x-1 h-11 self-center" role="group">
                        {['All', 'Execution', 'Testing'].map((option) => (
                            <button
                                key={option}
                                onClick={() => handleOnChooseReportType(option)}
                                className={`px-4 py-2  text-sm font-medium rounded-xl shadow-sm ${data.reportType === option
                                    ? 'bg-primary text-white'
                                    : 'bg-input  text-gray-700 hover:bg-gray-100'
                                    }`}
                                aria-pressed={data.reportType === option}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                }
            </div>

            <SelectInput label='Type' title='Type' options={types} id="type" name="type" value={data.type} onChange={handleOnSelectType} />

            <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700 mb-1 font-inter text-secondary">Description</label>
                <textarea type="text" id="description" name="description" placeholder="Description Here..." value={data.description} onChange={handleOnInputChange}
                    className="w-full  px-4 py-2 mt-1 rounded-lg  focus:ring-1  focus:ring-primary focus:border-primary shadow-sm bg-input placeholder-gray-400 text-gray-900 focus:outline-none transition duration-300 ease-in-out h-32" >
                </textarea>
            </div>

        </div>

    )
}



