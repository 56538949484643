import { useCallback, useContext, useEffect, useState } from "react";
import FilterSelectInput from "../components/FilterSelectInput";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import Datepicker from "react-tailwindcss-datepicker";
import { constructQueryParams } from "../utils/encodeURI";
//here calls

import { conversationDashboard, dashboard, dashboardByDays, getCounting } from '../services/apis/calls'

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { getAllUseCasesAPI } from "../services/apis/useCases";
import { AuthContext } from "../store/auth_context";
import NoData from "../components/NoData";
import { ReadByOrgId as ReadOrderRequests } from "../services/apis/orderRequest";
import { read as getConfigSettings } from '../services/apis/systemConfigs'
import CallAnalytics from "../components/dashboard/CallAnalytics";
import ConversationAnalytics from "../components/dashboard/ConversationAnalytics";
import BarChart from '../components/dashboard/BarChart'
import SummaryChart from "../components/dashboard/SummaryChart";
import BarChart2 from "../components/dashboard/BarChart2";
import DatePickerInput from "../components/inputs/DatePickerInput";
import { formatDateDDMMYY } from "../utils/formatting";
import SelectInput from "../components/inputs/SelectInput";


const types = [
    { text: "All", value: "all" },
    { text: "Testing", value: "simulation" },
    { text: "Execution", value: "execution" }
]

const counterColor = (inprogress, maxConcurrent) => {
    if (inprogress === 0) {
        return "text-gray-500"
    }

    if (inprogress / maxConcurrent * 50 > 37.5) {
        return "text-red-500"
    }

    if (inprogress / maxConcurrent * 50 < 37.5) {
        return "text-orange-500"
    }


}

const AnalyticsRow = ({ data, index }) => {
    const [isOpen, setIsOpen] = useState(false)
    let statuses = Object.entries(data.statuses)
    return (
        <div className="flex flex-col  mb-2 drop-shadow-sm">
            <div className={`grid grid-cols-3 justify-center  items-center text-center bg-input px-2 rounded-lg mb-2 cursor-pointer ${data.subStatus === "FAILED" ? "text-red-400" : data.subStatus === "SUCCEED" ? 'text-green-500' : 'text-black'}`} onClick={() => setIsOpen(!isOpen)}>
                <p className="text-sm  font-inter text-start flex" >
                    {isOpen ? <ChevronUpIcon className="text-gray-500 h-4 w-4 mr-3 self-center text-xs" /> : <ChevronDownIcon className="text-gray-500 h-4 w-4 mr-2 self-center" />}
                    {data.subStatus}
                </p>
                <p className="text-base font-inter ">{data.count}</p>
                <p className="text-base font-inter ">{toPercentage(data.percentage)}</p>
            </div>

            {isOpen &&
                <div className="ml-7">
                    {
                        statuses.map(([key, value]) => {
                            return (
                                <div className={`grid grid-cols-3 justify-center  items-center text-center `} key={key}>
                                    <p className="text-sm text-gray-500 font-inter text-start text-sm ml-5">{key}</p>
                                    <p className="text-sm text-gray-500  ">{value.count}</p>
                                    <p className="text-sm text-gray-500  ">{toPercentage(value.percentage)}</p>
                                </div>
                            )

                        })
                    }
                </div>
            }
        </div>
    )
}

const toPercentage = (num) => `${(num * 100).toFixed(2)}%`
const last10days = () => {
    const today = new Date()
    const startDate = new Date();
    startDate.setDate(today.getDate() - 10);
    return {
        gte: format(startDate),
        lte: format(today)
    }
}

const format = (date) => {
    const endDate = new Date(date)
    const day = String(endDate.getDate()).padStart(2, '0');
    const month = String(endDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
    const year = endDate.getFullYear();
    return `${day}-${month}-${year}`
}

export default function Dashboard() {
    const [data, setData] = useState([])
    const [chart, setChart] = useState([])
    const [conversations, setConversations] = useState([])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({ active: false, usecase: '', orderRequest: '', type: '', gte: '', lte: '' })
    const [useCases, setUseCases] = useState([])
    const [orderRequests, setOrderRequests] = useState([])
    const [range, setRange] = useState({ startDate: '', endDate: '' })
    const { profile } = useContext(AuthContext)
    const [maxConcurrent, setMaxConcurrent] = useState(0)
    const [counting, setCounting] = useState({ success: 0, faileds: 0, progress: 0, pendings: 0, cancelled: 0, activeJobOrders: 0, idle: 0 })

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleOnDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setFilters((previous) => {
                return {
                    ...previous,
                    gte: formatDateDDMMYY(start),
                    lte: formatDateDDMMYY(end)
                }
            })
        }
    }


    //handle On Date Change
    const handleDateChange = (value) => {
        value.startDate = value.startDate ? value.startDate : ''
        value.endDate = value.endDate ? value.endDate : ''
        setRange(value)

        if (value.startDate) {
            const startDate = new Date(value.startDate)
            if (!isNaN(startDate)) {
                const day = String(startDate.getDate()).padStart(2, '0');
                const month = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
                const year = startDate.getFullYear();
                setFilters((previous) => { return { ...previous, active: true, 'gte': `${day}-${month}-${year}` } })
            }
        } else {
            setFilters((previous) => { return { ...previous, 'gte': '' } })
        }

        if (value.endDate) {
            const endDate = new Date(value.endDate)
            if (!isNaN(endDate)) {
                const day = String(endDate.getDate()).padStart(2, '0');
                const month = String(endDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
                const year = endDate.getFullYear();
                setFilters((previous) => { return { ...previous, active: true, 'lte': `${day}-${month}-${year}` } })
            }
        } else {
            setFilters((previous) => { return { ...previous, 'lte': '' } })
        }


    }

    const handleSelectChange = (event) => {
        console.log('event.target.id', event.target.id, 'event.target.value', event.target.value)
        if (event.target.id === "type" && event.target.value === "simulation") {
            setFilters({ ...filters, active: true, [event.target.id]: event.target.value, orderRequest: '' })
        } else {
            setFilters({ ...filters, active: true, [event.target.id]: event.target.value })
        }
    }

    // const handleRemoveFilter = () => {
    //     setFilters({ ...filters, active: false, usecase: '', gte: '', lte: '', orderRequest: '', type: '' })
    // }

    const readCounting = useCallback(async () => {
        getCounting(profile.organization._id).then((response) => {
            console.log('counting', response)
            if (response.data && response.data.length > 0) {
                setCounting({ ...response.data[0] })
            }

        }).catch((error) => {
            console.log('error')
        })
    }, [profile.organization._id]);


    const readDashboard = useCallback(async () => {
        const query = constructQueryParams(filters);
        dashboard(profile.organization._id, query).then((response) => {
            // setData(response.filter((item) => item.subStatus === "FAILED" || item.subStatus === "SUCCEED").sort((a, b) => (a.subStatus === "SUCCEED" ? -1 : 1)))
            setData(response.filter((item) => item.subStatus === "FAILED" || item.subStatus === "SUCCEED").sort((a, b) => (a.subStatus > b.subStatus ? -1 : 1)))
            console.log('datasa', data)
        }).catch((error) => {
            console.log('error')
        })
    }, [filters, profile.organization._id]);

    const readChart = useCallback(async () => {

        let query = ''
        if (filters.active) {
            query = constructQueryParams(filters);
        } else {
            query = constructQueryParams(filters);
            // query = constructQueryParams(last10days());
        }

        console.log('query', query)
        dashboardByDays(profile.organization._id, query).then((response) => {

            console.log('readbydasy', response)
            // setChart(response.map((item) => {
            //     return {
            //         "day": item.date,
            //         "SUCCEED": item.statuses.SUCCEED || 0,
            //         "FAILED": item.statuses.FAILED || 0
            //     }
            // }))
            setChart(response.map((item) => {
                return {
                    ...item,
                    statuses: {
                        SUCCEED: item.statuses.SUCCEED || 0,
                        FAILED: item.statuses.FAILED || 0
                    }
                }
            }))
        }).catch((error) => {

            console.log('error')
        })
    }, [filters, profile.organization._id]);

    const readConversationDashboard = useCallback(async () => {

        let query = ''
        if (filters.active) {
            query = constructQueryParams(filters);
        } else {
            query = constructQueryParams(filters);
        }

        console.log('query', query)
        conversationDashboard(profile.organization._id, query).then((response) => {
            console.log('conversationDashboard', response)
            setConversations(response.result)
        }).catch((error) => {

            console.log('error')
        })
    }, [filters, profile.organization._id]);


    useEffect(() => {
        const fetchAll = () => {
            readCounting();
            readDashboard();
            readChart();
            readConversationDashboard();
        };

        fetchAll();

        const interval = setInterval(fetchAll, 15000);

        return () => {
            clearInterval(interval);
        };

    }, [readCounting, readDashboard, readChart, readConversationDashboard]);

    useEffect(() => {
        getAllUseCasesAPI(profile.organization._id).then((response) => {
            console.log(response)
            if (response && response.data) {
                setUseCases(response.data.usecases.map((usecase) => {
                    return { text: usecase.name, value: usecase._id }
                }))
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [profile.organization._id])

    useEffect(() => {
        setLoading(true)
        ReadOrderRequests(profile.organization._id).then((response) => {
            setOrderRequests(response.map((orderRequest) => {
                return { text: orderRequest.name, value: orderRequest._id }
            }))
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }, [profile.organization._id])

    useEffect(() => {
        getConfigSettings(profile.organization._id).then((response) => {
            setMaxConcurrent(response.max_concurrent_calls)
        }).catch((error) => {
            console.log('error', error)
        })
    }, [profile.organization._id])

    if (loading) {
        return (
            <Layout title='Dashboard'>
                <Loading />
            </Layout>
        )

    }

    return (
        <Layout title='Dashboard'>


            <div className="flex gap-5  rounded-lg ">


                <SelectInput name="usecase" title='Voice Agent' options={useCases} value={filters.usecase} id='usecase' onChange={handleSelectChange} />
                {/* <FilterSelectInput name="Voice Agent Filter" options={useCases} value={filters.usecase} id='usecase' onChange={handleSelectChange}></FilterSelectInput> */}

                {/* {filters.type && filters.type === "simulation" ? undefined :
                    <FilterSelectInput name="Job Order" options={orderRequests} value={filters.orderRequest} id='orderRequest' onChange={handleSelectChange}></FilterSelectInput>
                } */}

                {/* <FilterSelectInput name="Type" options={types} value={filters.type} id='type' onChange={handleSelectChange}></FilterSelectInput> */}
                <SelectInput name="type" title='Type' options={types} value={filters.type} id='type' onChange={handleSelectChange} />


                {/* <Datepicker separator="to" showFooter={true} value={range} onChange={(value) => handleDateChange(value)}
                    placeholder="Date Range Filter"
                    containerClassName="relative dark:bg-white"
                    inputClassName="  h-10 w-full px-5  rounded-r-lg  font-inter   shadow-sm dark:bg-select  bg-select text-md font-light focus:outline-none focus:ring-2 focus:ring-primary "
                    toggleClassName="absolute bg-primary rounded-r-lg text-white right-0 h-full px-3 text-gray-400 "></Datepicker> */}
                <DatePickerInput handleOnDateChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
                {/* <button onClick={handleRemoveFilter} className="flex  ml-auto justify-center items-center bg-red-400 px-5 text-white rounded-lg align-base">Cancel <XCircleIcon className="h-5 w-5 ml-2 text-white font-bold text-red-400 cursor-pointer" /></button> */}
            </div>


            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
                <div className="col-span-2 rounded-lg bg-white shadow-lg border p-8 max-h-[500px] overflow-y-auto">
                    <CallAnalytics data={data} />
                    <hr className="my-4" />
                    <ConversationAnalytics data={conversations} />
                </div>
                <div className="col-span-1 flex flex-col  space-y-5 grow">
                    <div className="rounded-lg bg-gray-100 shadow-lg h-full border">
                        <div className="flex items-center float-right mt-4 mr-4">
                            <span className="relative flex h-3 w-3 mr-2">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-400"></span>
                            </span>
                            <span className="text-red-400 animate-pulse font-bold">Live</span>
                        </div>
                        <div className=" px-5 pt-5 flex flex-col justify-center items-center space-y-4 clear-right">
                            <p className="text-sm text-gray-500">In Progress / <span className="ml-1 font-bold"> Max CC ({maxConcurrent})</span></p>
                            <div className="relative size-32">
                                <svg className="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="1" strokeDasharray="50 100" strokeLinecap="round"></circle>
                                    <circle cx="18" cy="18" r="16" fill="none" className={`stroke-current ${counterColor(counting.progress, maxConcurrent)} animate-pulse`} strokeWidth="1" strokeDasharray={`${counting.progress === 0 ? 0 : counting.progress / maxConcurrent * 50} 100`} strokeLinecap="round"></circle>
                                </svg>
                                <div className="absolute top-9 start-1/2 transform -translate-x-1/2 text-center">
                                    <span className={`text-3xl font-bold ${counterColor(counting.progress, maxConcurrent)} `}>{counting.progress}    </span>
                                    <span className={`text-sm ${counterColor(counting.progress, maxConcurrent)} block `}>Progress</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="rounded-lg bg-white shadow-lg p-5 flex justify-center space-x-10 items-center  h-full border">

                        <div className={`text-center `}>
                            <p className="text-sm text-gray-500">In Queue</p>
                            <p className="text-xl font-semibold text-gray-800">{counting.idle} </p>
                        </div>

                        <div className={`text-center`}>
                            <p className="text-sm text-gray-500">Active Job Orders</p>
                            <p className="text-xl font-semibold text-gray-800">{counting.activeJobOrders} </p>
                        </div>

                        <div className={`text-center `}>
                            <p className="text-sm text-gray-500">In Pending</p>
                            <p className="text-xl font-semibold text-gray-800">{counting.pendings} </p>
                        </div>
                    </div>
                </div>
            </div>



            {/* {chart.length > 0 &&
                // <div className="grid grid-cols-2 gap-5">


                //     <div className="my-10 bg-white py-5 rounded-lg shadow-lg border ">
                //         <BarChart />
                //     </div>
                //     <div className="my-10 bg-white py-5 rounded-lg shadow-lg border ">
                //         <StackedBarChart data={chart} />
                //     </div>
                // </div>


                <div className="bg-white rounded-lg shadow-lg border py-10">
                    <BarChart data={chart} />

                </div>
            }


            <div className="p-4  ">
                <SummaryChart />
            </div> */}


            {chart.length > 0 &&
                <div className="w-full p-4 bg-white rounded-lg shadow-md relative p-4 border">
                    <div className="flex justify-between mb-4">
                        <h2 className="text-lg font-semibold">Summary</h2>

                    </div>

                    <div className="flex space-x-2 justify-between">
                        <div className="h-[300px] w-3/4">
                            <BarChart2 data={chart} />
                        </div>
                        <div className=" flex space-x-4 h-10 self-end mb-5">
                            <button className="px-6 py-2  rounded-lg bg-red-400 text-white" disabled={true}>Failed</button>
                            <button className="px-6 py-2 bg-secondary-400 text-white rounded-lg" disabled={true}>Succeed</button>
                        </div>
                    </div>

                </div>



            }
        </Layout>
    )
}



// useEffect(() => {
//     readCounting()
//     readDashboard();
//     readChart()
//     readConversationDashboard()

//     const interval = setInterval(readCounting, 20000);
//     const interval1 = setInterval(readDashboard, 20000);
//     const interval2 = setInterval(readChart, 20000);
//     const interval3 = setInterval(readConversationDashboard, 20000);

//     return () => {
//         clearInterval(interval);
//         clearInterval(interval1);
//         clearInterval(interval2);
//         clearInterval(interval3);
//     };

// }, [readCounting, readDashboard, readChart, readConversationDashboard]);