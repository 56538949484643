import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
const toPercentage = (num) => `${(num * 100).toFixed(2)}%`


const AnalyticsRow = ({ data, index }) => {
    const [isOpen, setIsOpen] = useState(false)
    let statuses = Object.entries(data.statuses)
    return (
        <div className="flex flex-col  mb-2 drop-shadow-sm">
            <div className={`grid grid-cols-3 justify-center  items-center text-center bg-input px-2 rounded-lg mb-2 cursor-pointer ${data.subStatus === "FAILED" ? "text-red-400" : data.subStatus === "SUCCEED" ? 'text-green-500' : 'text-black'}`} onClick={() => setIsOpen(!isOpen)}>
                <p className="text-sm  font-inter text-start flex" >
                    {isOpen ? <ChevronUpIcon className="text-gray-500 h-4 w-4 mr-3 self-center text-xs" /> : <ChevronDownIcon className="text-gray-500 h-4 w-4 mr-2 self-center" />}
                    {data.subStatus}
                </p>
                <p className="text-base font-inter ">{data.count}</p>
                <p className="text-base font-inter ">{toPercentage(data.percentage)}</p>
            </div>

            {isOpen &&
                <div className="ml-7">
                    {
                        statuses.map(([key, value]) => {
                            return (
                                <div className={`grid grid-cols-3 justify-center  items-center text-center `} key={key}>
                                    <p className="text-sm text-gray-500 font-inter text-start text-sm ml-5">{key}</p>
                                    <p className="text-sm text-gray-500  ">{value.count}</p>
                                    <p className="text-sm text-gray-500  ">{toPercentage(value.percentage)}</p>
                                </div>
                            )

                        })
                    }
                </div>
            }
        </div>
    )
}


export default function CallAnalytics({ data }) {
    return (
        <>
            <p className="text-base font-inter text-start font-medium mb-2">Call Analytics</p>
            <div className='grid grid-cols-3 justify-center items-center text-center text-secondary font-normal text-base  mb-4'>
                <p className="text-base font-inter text-start">Call Status</p>
                <p className="text-base font-inter">Count</p>
                <p className="text-base font-inter">Percent</p>
            </div>

            {data.map((item, index) => {
                return (
                    <AnalyticsRow data={item} index={index} key={index} />
                )
            })}

            <div className='grid grid-cols-3 justify-center  items-center text-center font-semibold text-sm mt-4'>
                <p className="font-inter text-start ml-5  text-secondary ">Total</p>
                <p className="text-gray-500 font-inter  text-secondary ">{data.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)}</p>
                <p className="text-gray-500 font-inter   text-secondary">{toPercentage(data.reduce((accumulator, currentValue) => accumulator + currentValue.percentage, 0.0))}</p>
            </div>
        </>
    )
}