

export default function SelectInput({ label, title, disabledTitle = true, options, ...props }) {
    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1 font-inter text-secondary">
                {label}
            </label>
            <select {...props} className="w-full h-11 px-4 py-2 mt-1 rounded-lg 
                     focus:ring-1  focus:ring-primary focus:border-primary
                     shadow-sm bg-input placeholder-gray-400 
                     text-gray-900 focus:outline-none transition duration-300 ease-in-out min-w-56">
                <option value='' disabled={disabledTitle}>{title}</option>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>{option.text}</option>
                    )
                })}
            </select>
        </div>
    )
}