import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const SummaryChart = () => {
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
            {
                label: "Data Scaler",
                data: [60, 20, 80, 40, 100, 30], // Replace with your data
                backgroundColor: [
                    "#543EC1",
                    "#F0E5FC",

                ],
                borderRadius: 10, // Rounded corners for bars
                barThickness: 40, // Fixed bar width
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide legend
            },
            tooltip: {
                enabled: true, // Show tooltips
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Hide grid lines on x-axis
                },
            },
            y: {
                ticks: {
                    callback: (value) => `${value}k`, // Format y-axis labels
                },
                beginAtZero: true,
                grid: {
                    borderDash: [5], // Dashed grid lines
                },
                max: 120, // Set the maximum height for the bars (y-axis limit)

            },
        },
    };

    return (
        <div className="w-full p-4 bg-white rounded-lg shadow-md">
            <div className="flex justify-between mb-4">
                <h2 className="text-lg font-semibold">Summary</h2>
                <button className="text-blue-600">Data Scaler</button>
            </div>
            <div className="h-[300px]">
                <Bar data={data} options={options} />
            </div>
            <div className="flex justify-end mt-4 space-x-4">
                <button className="px-4 py-2 bg-blue-600 text-white rounded-lg">Succeed</button>
                <button className="px-4 py-2 bg-blue-100 text-blue-600 rounded-lg">Failed</button>
            </div>
        </div>
    );
};

export default SummaryChart;
