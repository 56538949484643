import moment from "moment"
import Pagination from "../Pagination"



export default function PedningTable({ header, data, filters, paginate, limitation, allChecked, selectedRows, handleSelectAll, handleSelectRow }) {
    return (
        <div className="relative overflow-x-auto  rounded-xl border  shadow-sm py-6">
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">

                <thead className="text-xs text-primary uppercase  h-12 ">
                    <tr className="border-b border-gray-200   font-inter text-center">
                        <th scope="col" className="px-6 py-3" >
                            <input
                                type="checkbox"
                                checked={allChecked}
                                onChange={handleSelectAll}
                                className="form-checkbox text-primary   "
                            />
                        </th>
                        {header.map((item, index) => {
                            return (
                                <td key={index} className="px-6 py-3  whitespace-nowrap" >{item}</td>
                            )
                        })}

                    </tr>
                </thead>

                <tbody>
                    {data.data.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex} className={`${rowIndex >= data.length - 1 ? undefined : 'border-b border-gray-200'} font-inter h-20 text-center`}>
                                <td className="">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(row._id)}
                                        onChange={() => handleSelectRow(row._id)}
                                        className="form-checkbox text-primary"
                                    />
                                </td>
                                {header.map((col, colIndex) => (
                                    <td key={colIndex} className="px-6 py-3 align-middle" >{col === "Date & Time" ? moment(row[col]).format('DD-MM-YYYY') : row[col]}</td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
            </table>


            <Pagination total={data.total} limit={filters.limit} paginate={paginate} limitation={limitation} />

        </div>
    )
}