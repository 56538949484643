import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import TextInput from '../inputs/TextInput'


export default function ModifyOrganizationDialog({ isOpen, setIsOpen, eventFunction, profile, setProfile, setSelectedFile }) {
    const cancelRef = useRef()
    const fileInputRef = useRef(null)

    const handleOnInputChange = (event) => {
        setProfile((previous) => {
            return {
                ...previous,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setProfile((previous) => {
                return {
                    ...previous,
                    logo: URL.createObjectURL(file)
                }
            });
        }
    };

    // const handleCheckboxChange = () => {
    //     setIsChecked(prev => !prev);
    //     setProfile((previous) => {
    //         return {
    //             ...previous,
    //             environment: isChecked ? 'production' : 'development'
    //         }
    //     })
    // };

    const handleOnCancel = () => {
        setIsOpen(false)
    }

    const handleOnConfirm = () => {
        setIsOpen(false)
        eventFunction()
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)} initialFocus={cancelRef}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">Modify Organization</Dialog.Title>

                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">apply all modefications needed, like change logo, name and conversation ai platform environement</p>
                                </div>


                                <div className='p-5'>
                                    <div className='flex justify-between'>
                                        <div className="w-[400px]  relative border-2 border-gray-300 border-dashed rounded-lg mb-4">
                                            <div className="text-center">
                                                <img className="w-full h-40 mx-auto object-contain transition-all duration-300 " src={profile.logo ? profile.logo : '/img/image-removebg-preview.png'} alt="..." />
                                            </div>
                                        </div>

                                        <div className='flex justify-center items-center flex-col text-center'>
                                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                <label className="relative cursor-pointer">
                                                    <span>organization logo</span>
                                                    <span className="text-indigo-600 cursor-pointer" onClick={handleButtonClick}> browse </span>
                                                    <span>to upload</span>
                                                </label>
                                            </h3>

                                            <p className="mt-1 text-xs text-gray-500">
                                                PNG, JPG, JPEG up to 10MB
                                            </p>
                                        </div>

                                        <input
                                            type="file"
                                            id="imageUpload"
                                            accept="image/*"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                        />
                                    </div>


                                    <div className='w-3/4'>
                                        <TextInput type="text" id="name" name="name" placeholder="Name" value={profile.name} onChange={handleOnInputChange} label="Name" />
                                        <TextInput type="text" id="conversation_ai_platform" name="conversation_ai_platform" placeholder="Conversation AI Platform" label="Conversation AI Platform" value={profile.conversation_ai_platform} onChange={handleOnInputChange} />
                                        {/* <div className="my-1 flex justify-between">
                                            <label className="inline-flex items-center mb-5 cursor-pointer">
                                                <p className="inline mr-3"><span className={`${isChecked && "text-primary"} font-normal m-1`}>Production</span>/<span className={`${!isChecked && "text-primary"} font-normal m-1`}>Development</span></p>
                                                <input type="checkbox" name='environment' id="environment" checked={profile.environment === "production" ? true : false} onChange={handleCheckboxChange} className="shadow-sm w-4 h-4  border-gray-300 rounded focus:ring-primary focus:ring-2 font-inter sr-only peer" />
                                                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                                            </label>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="mt-4 flex justify-end space-x-2 ">
                                    <button ref={cancelRef} onClick={() => handleOnCancel()} type="button" className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:ring-offset-1">
                                        Cancel
                                    </button>

                                    <button onClick={() => handleOnConfirm()} type="button" className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2">
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>



    )
}