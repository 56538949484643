import SelectInput from "../../../components/inputs/SelectInput";
import TextInput from "../../../components/inputs/TextInput";


const roleOptions = [
    { text: "Administrator", value: "administrator" },
    { text: "Tester", value: "tester" },
    { text: "Call Manager", value: "call_manager" },
    { text: "Monitor", value: "monitor" }
];

export default function AddUserForm({ user, setuser, createUser }) {
    return (
        <div className=" shadow-sm border p-8 bg-white rounded-lg">
            <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                Add and Manage Users
            </h1>
            <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                Adding new profiles, updating existing ones, and assigning roles or permissions. Provide essential information such as user name, email, and role to ensure accurate access control.
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-5  gap-5">
                <TextInput label="Name" placeholder="John doe" name="name" id="name" value={user.name} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />
                <TextInput label="Username" placeholder="john" name="email" id="email" value={user.email} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />
                <TextInput label="Password" placeholder="*********" name="password" id="password" type="password" value={user.password} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />
                <SelectInput label="Access Type" options={roleOptions} title='select' id="role" name="role" value={user.role} onChange={(event) => { setuser({ ...user, [event.target.name]: event.target.value }); }} />

                <button
                    onClick={() => createUser()}
                    type="button"
                    className="ml-0 lg:ml-10 self-center py-2 px-4 text-sm font-medium rounded-md text-white bg-primary font-inter shadow-sm h-11 "
                >
                    Create user
                </button>
            </div>
        </div>
    )
}