
import { Fragment, useContext, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Sidebar from './sidebar/Sidebar'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../store/auth_context'

export default function Layout({ children, title }) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { profile, signOut } = useContext(AuthContext)
    const navigate = useNavigate()

    const handleOnLogout = () => {
        signOut()
        navigate('/')
    }
    return (
        <div>
            {/*For Mobile Sidebar*/}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <Sidebar></Sidebar>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/*For Mobile Open Sidebar */}
            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-default px-4 py-4 shadow-lg sm:px-6 lg:hidden">
                <button type="button" className="-m-2.5 p-2.5  lg:hidden" onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>


            {/*For Desktop Sidebar*/}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
                <Sidebar></Sidebar>
            </div >


            {/*For Main Page */}
            <main className=" lg:pl-64 bg-default min-h-screen">
                <div className=" hidden lg:flex h-16 border border-b-1 flex items-center px-12 shadow-sm">
                    <p className='text-xl text-coal font-inter font-normal '>{title}</p>
                    <div className="flex items-center space-x-4 ml-auto font-inter">
                        {/* Name and Role */}
                        <div className="text-right">
                            <div className="text-sm font-medium text-gray-900">{profile.name}</div>
                            <div className="text-xs text-gray-500">{profile.role}</div>
                        </div>
                        <button onClick={() => handleOnLogout()} className="flex items-center text-red-400  p-2  hover:bg-default font-inter ">
                            <img src='/icons/Logout.png' alt="Headphone Icon" className="w-5 h-5 mr-2" />
                        </button>
                    </div>
                </div>
                <div className='px-12 py-8 '>
                    {children}
                </div>

            </main>
        </div >
    )
}