import { Dialog, Transition } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import Stepper from './Stepper'
import ReportInfo from './steppers/ReportInfo'
import CustomFilters from './steppers/CustomFilters'
import SelectFields from './steppers/SelectFields'


export default function ReportDialog2({ setCurrentStep, currentStep, isOpen, handleOnCloseCreateModal, data, setData, useCases, dateType, voiceAgents, setFields, setQuestionFields, setCheckpoints, checkpoints, setconversationStatuses, conversationStatuses, handleOnCreateReport, handleOnUpdateReport, questionFields, systemfields, fields, mode, handleOnDateChange, startDate, endDate, setDateType }) {


    const steps = [{ label: 'Report Info' }, { label: 'Custom Filters' }, { label: 'Select Fields' },];

    const handleNext = () => {
        if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        if (currentStep > 0) setCurrentStep(currentStep - 1);
    };


    const handleOnInputChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }

    //All, Execution, Testing
    const handleOnChooseReportType = (type) => {
        setData((previous) => { return { ...previous, reportType: type } })
    }

    //Calls, Conversations, Batches, Job Orders
    const handleOnSelectType = (event) => {
        if (event.target.value !== "calls" && event.target.value !== "conversations") {
            setData({ ...data, 'subStatus': '', 'fields': [], 'checkPoint': '', 'system_fields': [], [event.target.name]: event.target.value })
        } else {
            setData({ ...data, [event.target.name]: event.target.value })
        }
    }


    //Selecting voiceagent control fields, questions and statueses
    const handleOnSelectvoiceAgent = (event) => {
        const voiceAgent = voiceAgents.find((field) => field._id === event.target.value)
        const keys = []
        const questions = []
        for (const field of Object.values(voiceAgent.fields)) {
            keys.push(field.label)
        }
        for (const question of Object.values(voiceAgent.custom_questions)) {
            questions.push(question.question)
        }
        setData({ ...data, [event.target.name]: event.target.value })
        setFields(keys)
        setQuestionFields(questions)
        // setCheckpoints(voiceAgent.checkpoints.map((item) => ({ text: item.label, value: item.id })))
        setconversationStatuses(Array.from(new Map(voiceAgent.checkpoints.map(item => [item.status, { text: item.status, value: item.status }])).values()))

    }

    //Selecting conversation status will control which checkpoints showd (multiple selections checkpoints)
    const handleOnSelectConversationStatus = (event) => {
        if (event.target.value) {
            const voiceAgent = voiceAgents.find((field) => field._id === data.usecase)
            const checkpoints = voiceAgent.checkpoints.filter(item => item.status === event.target.value)
            setCheckpoints(checkpoints.map((item) => ({ label: item.label, value: item.id })))
            setData({ ...data, [event.target.name]: event.target.value, checkpoints: [] })
        } else {
            setData({ ...data, [event.target.name]: event.target.value, checkpoints: [] })
        }

    }


    const handleOnChangeDateType = (event) => {
        if (event.target.value === "range") {
            setDateType(event.target.value)
            setData({ ...data, lastN: '' })
        } else {
            setDateType(event.target.value)
            setData({ ...data, start_date: '', end_date: '' })
        }
    }

    const handleOnChooseCheckpoints = (id) => {
        console.log('id', id)
        setData((previous) => {
            return {
                ...previous,
                checkpoints: previous.checkpoints.includes(id)
                    ? previous.checkpoints.filter((item) => item !== id)
                    : [...previous.checkpoints, id]
            }
        })
    };

    console.log('datadata', data)
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => handleOnCloseCreateModal()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ">
                                {/* <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-4">Create Report</Dialog.Title> */}

                                <div className='flex space-x-10 w-full  py-10'>
                                    <button onClick={handlePrevious} disabled={currentStep === 0}>
                                        <ChevronLeftIcon className={`w-6 h-6 p-1 rounded-full text-sm font-medium text-white bg-gray-400 rounded ${currentStep === 0 ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-500'}`} />
                                    </button>


                                    <div className='w-full'>
                                        <Stepper steps={steps} currentStep={currentStep} />
                                        <div className="mt-8 p-4 bg-white h-[500px] overflow-y-auto">

                                            {currentStep === 0 &&
                                                <ReportInfo
                                                    data={data}
                                                    handleOnInputChange={handleOnInputChange}
                                                    handleOnChooseReportType={handleOnChooseReportType}
                                                    handleOnSelectType={handleOnSelectType} />}

                                            {currentStep === 1 &&
                                                <CustomFilters
                                                    useCases={useCases}
                                                    data={data}
                                                    dateType={dateType}
                                                    handleOnInputChange={handleOnInputChange}
                                                    handleOnSelectvoiceAgent={handleOnSelectvoiceAgent}
                                                    conversationStatuses={conversationStatuses}
                                                    handleOnSelectConversationStatus={handleOnSelectConversationStatus}
                                                    checkpoints={checkpoints}
                                                    handleOnDateChange={handleOnDateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    handleOnChangeDateType={handleOnChangeDateType}
                                                    handleOnChooseCheckpoints={handleOnChooseCheckpoints} />}

                                            {currentStep === 2 &&
                                                <SelectFields
                                                    data={data}
                                                    setData={setData}
                                                    mode={mode}
                                                    fields={fields}
                                                    systemfields={systemfields}
                                                    questionFields={questionFields}
                                                    handleOnCloseCreateModal={handleOnCloseCreateModal}
                                                    handleOnUpdateReport={handleOnUpdateReport}
                                                    handleOnCreateReport={handleOnCreateReport}
                                                />}
                                        </div>
                                    </div>

                                    <button onClick={handleNext} disabled={currentStep === steps.length - 1}>
                                        <ChevronRightIcon className={`w-6 h-6 p-1 rounded-full text-sm font-medium text-white bg-gray-400 rounded ${currentStep === steps.length - 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-500'}`} />
                                    </button>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}