import { useRef } from "react";
import TextInput from "../../components/inputs/TextInput";



export default function Details({ organization, handleOnUpdateDetails, handleOnInputChange, handleOnImageChange }) {
    const logo = useRef(null)

    const handleOnLogoClicked = () => {
        logo.current.click();
    };

    return (
        <div className="px-12 pt-4">

            <div className=" flex justify-between flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap mb-2">
                <div className="md:mb-5 sm:mb-5">
                    <p className="text-xl font-inter text-secondary font-semibold mb-2">Organization Details <span className="ml-1 text-lg text-primary opacity-75">{organization.name || ''}</span></p>
                    <p className="text-md font-inter text-gray-400 font-medium leading-normal mb-2 w-full lg:w-3/4">Manage account settings, including updating the organization's logo and configuring maximum concurrency limits</p>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 max-w-md ">
                        <div className="p-4 bg-gray-100 rounded-lg">
                            <h4 className="text-sm font-medium text-gray-700">Conversation AI Platform</h4>
                            <p className="text-primary-600">{organization.conversation_ai_platform || ''}</p>
                        </div>
                        <div className="p-4 bg-gray-100 rounded-lg">
                            <h4 className="text-sm font-medium text-gray-700">Environment</h4>
                            <p className="text-primary-600">{organization.environment || ''}</p>
                        </div>
                    </div>



                    <div className="w-full lg:w-3/4 mt-4">
                        <TextInput label='Max Concurrent' type='number' placeholder='Max Concurrent' name='max_concurrent_calls' id='max_concurrent_calls' value={organization.max_concurrent_calls || ''} onChange={handleOnInputChange} />
                    </div>
                </div>


                <div className={`flex flex-col justify-center items-center  rounded-xl p-10 ${organization.logo ? 'bg-white border' : 'bg-primary-50'}`}>
                    <button><img src={organization.logo ? organization.logo : `/img/data-uploader.png`} alt="..." className={`mb-2 ${organization.logo && 'h-36 w-48 object-contain border-b'}`} onClick={() => handleOnLogoClicked()} /></button>
                    <p className="font-inter text-center font-light text-sm text-gray-400 w-3/4">Upload files from data files for your product</p>
                    <p className="font-inter text-center font-light text-sm text-gray-400 w-3/4">File Format .jpg .png</p>
                    <input type="file" id="logo" name='logo' className="hidden" accept="image/*" ref={logo} onChange={handleOnImageChange} />
                </div>

            </div>


            <button className="py-1 px-8 bg-primary-500 hover:bg-primary-600 transition duration-300 ease-in-out text-white shadow-sm rounded-lg" onClick={() => handleOnUpdateDetails()}>Save</button>

        </div>

    )
}