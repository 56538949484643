
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef } from 'react'

export default function ConfirmDialog({ isOpen, setIsOpen, title, description, eventFunction }) {
    const cancelRef = useRef()

    const handleOnCancel = () => {
        setIsOpen(false)
    }

    const handleOnConfirm = () => {
        setIsOpen(false)
        eventFunction()
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)} initialFocus={cancelRef}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900"> {title}</Dialog.Title>

                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">{description}</p>
                                </div>

                                <div className="mt-4 flex justify-end space-x-2 ">
                                    <button ref={cancelRef} onClick={() => handleOnCancel()} type="button" className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-1 focus-visible:ring-red-500 focus-visible:ring-offset-1">
                                        Cancel
                                    </button>

                                    <button onClick={() => handleOnConfirm()} type="button" className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2">
                                        Yes
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>



    )
}