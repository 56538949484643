
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'

export default function BatchDetailsDialog({ isOpen, setIsOpen, data }) {

    console.log('datasdf', data)
    const headers = data.length > 0 ? Object.keys(data[0]) : [];
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)} >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden h-full rounded-2xl bg-white text-left align-middle shadow-xl transition-all">

                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 m-4">Batch Details ({data.length})</Dialog.Title>
                                <div className="relative overflow-x-auto overflow-y-scroll  max-h-screen rounded-xl   shadow-sm py-2">
                                    <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">

                                        <thead className="text-xs text-primary uppercase  h-12 ">
                                            <tr className="border-b border-gray-200   font-inter text-center">
                                                <th scope="col" className="font-semibold px-6 py-3 w-10 sticky top-0 bg-white" > #</th>
                                                {headers.map((item, index) => {
                                                    return (
                                                        <td key={index} className="px-6 py-3  whitespace-nowrap sticky top-0 bg-white" >{item}</td>
                                                    )
                                                })}

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data.map((row, rowIndex) => {
                                                return (
                                                    <tr key={rowIndex} className={`${rowIndex >= data.length - 1 ? undefined : 'border-b border-gray-200'} font-inter h-20 text-center`}>
                                                        <th scope="col" className="font-semibold px-6 py-3 w-10 " >{rowIndex + 1}</th>
                                                        {headers.map((col, colIndex) => (
                                                            <td key={colIndex} className="px-6 py-3 align-middle" >{row[col]}</td>
                                                        ))}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>




                                </div>
                                <button onClick={() => setIsOpen(false)} className="py-2 px-8 rounded-lg shadow-sm   text-white  float-right text-md bg-red-400  border-1 hover:bg-red-500 m-4 hover:text-white leading-none font-semibold outline-none focus:outline-none">
                                    Close
                                </button>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}