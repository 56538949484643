import { useCallback, useContext, useEffect, useState } from "react"
import { AuthContext } from "../../store/auth_context"
import Layout from "../../components/Layout"
import Filters from "./Filters"
import CallAnalytics from './CallAnalytics'
import ConversationAnalytics from './ConversationAnalytics'
import LiveBoard from "./LiveBoard"
import BarChart2 from "../../components/dashboard/BarChart2"
import { getCallAnalytics, getCallAnalyticsVisualization, getConversationAnalytics, getCounting } from "../../services/apis/calls"
import Loading from "../../components/Loading"
import { constructQueryParams } from "../../utils/encodeURI"


export default function Dashboard2() {

    const { profile } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)

    const [callAnalytics, setCallAnalytics] = useState([])
    const [conversationAnalytics, setConversationAnalytics] = useState([])
    const [callAnalyticsVisualization, setCallAnalyticsVisualization] = useState([])


    const [counting, setCounting] = useState({ success: 0, faileds: 0, progress: 0, pendings: 0, cancelled: 0, activeJobOrders: 0, idle: 0 })
    const [filters, setFilters] = useState({ active: false, usecase: '', orderRequest: '', type: '', gte: '', lte: '' })


    const readCounting = useCallback(async () => {
        getCounting(profile.organization._id).then((response) => {
            console.log('counting', response)
            if (response.data && response.data.length > 0) {
                setCounting({ ...response.data[0] })
            }
        }).catch((error) => {
            setCounting({ success: 0, faileds: 0, progress: 0, pendings: 0, cancelled: 0, activeJobOrders: 0, idle: 0 })
            console.log('error', error)
        })
    }, [profile.organization._id]);

    const readCallAnalytics = useCallback(async () => {
        const query = constructQueryParams(filters);
        getCallAnalytics(profile.organization._id, query).then((response) => {
            console.log('readCallAnalytics', response)
            setCallAnalytics(response.filter((item) => item.subStatus === "FAILED" || item.subStatus === "SUCCEED").sort((a, b) => (a.subStatus > b.subStatus ? -1 : 1)))
        }).catch((error) => {
            setCallAnalytics([])
            console.log('error', error)
        })
    }, [filters, profile.organization._id]);


    const readConversationAnalytics = useCallback(async () => {
        const query = constructQueryParams(filters)
        getConversationAnalytics(profile.organization._id, query).then((response) => {
            console.log('readConversationAnalytics', response)
            setConversationAnalytics(response.result)
        }).catch((error) => {
            setConversationAnalytics([])
            console.log('error', error)
        })
    }, [filters, profile.organization._id]);

    const readCallAnalyticsVisualization = useCallback(async () => {
        const query = constructQueryParams(filters)
        getCallAnalyticsVisualization(profile.organization._id, query).then((response) => {
            setCallAnalyticsVisualization(response.map((item) => {
                return {
                    ...item,
                    statuses: {
                        SUCCEED: item.statuses.SUCCEED || 0,
                        FAILED: item.statuses.FAILED || 0
                    }
                }
            }))
        }).catch((error) => {
            setCallAnalyticsVisualization([])
            console.log('error', error)
        })
    }, [filters, profile.organization._id]);

    useEffect(() => {
        const fetchAll = () => {
            readCounting();
            readCallAnalytics();
            readConversationAnalytics();
            readCallAnalyticsVisualization();
        };

        fetchAll();
        const interval = setInterval(fetchAll, 15000);
        return () => { clearInterval(interval); };
    }, [readCounting, readCallAnalytics, readConversationAnalytics, readCallAnalyticsVisualization]);

    if (loading) { return (<Layout title='Dashboard'><Loading /></Layout>) }

    return (
        <Layout title='Dashboard'>
            <Filters filters={filters} setFilters={setFilters} />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
                <div className="col-span-2 rounded-lg bg-white shadow-lg border p-8 max-h-[500px] overflow-y-auto">
                    <CallAnalytics data={callAnalytics} />
                    <hr className="my-4" />
                    <ConversationAnalytics data={conversationAnalytics} />
                </div>
                <LiveBoard maxConcurrent={2} counting={counting} />
            </div>

            <div className="w-full p-4 bg-white rounded-lg shadow-md relative p-4 border">
                <div className="flex justify-between mb-4">
                    <h2 className="text-lg font-semibold">Summary</h2>
                </div>
                <div className="flex space-x-2 justify-between">
                    <div className="h-[300px] w-3/4">
                        <BarChart2 data={callAnalyticsVisualization} />
                    </div>
                    <div className=" flex space-x-4 h-10 self-end mb-5">
                        <button className="px-6 py-2  rounded-lg bg-red-400 text-white" disabled={true}>Failed</button>
                        <button className="px-6 py-2 bg-secondary-400 text-white rounded-lg" disabled={true}>Succeed</button>
                    </div>
                </div>

            </div>
        </Layout>
    )
}