
import React from 'react';

const Stepper = ({ steps, currentStep }) => {
    return (
        <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 sm:text-base">
            {steps.map((step, index) => (
                <li
                    key={index}
                    className={`flex items-center ${index < steps.length - 1 ? 'md:w-full after:w-full after:h-1 after:border-b after:border-gray-400 after:mx-6 xl:after:mx-10' : ''
                        } ${index <= currentStep ? 'text-primary' : 'text-gray-400'}`}
                >
                    <span className="flex items-center w-full">
                        {index <= currentStep ? (
                            <svg
                                className="w-4 h-4 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                        ) : (
                            <span className="mr-2">{index + 1}</span>
                        )}
                        {step.label}
                    </span>
                </li>
            ))}
        </ol>
    );
};

export default Stepper;
