import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart2 = ({ data }) => {

    // Extract labels (dates) and datasets
    const labels = data.map((entry) => entry.date);
    const failedData = data.map((entry) => entry.statuses.FAILED || 0);
    const succeedData = data.map((entry) => entry.statuses.SUCCEED || 0);

    const chartData = {
        labels,
        datasets: [
            {
                label: "FAILED",
                data: failedData,
                backgroundColor: "#F26A6A",
                borderRadius: 10,
                barThickness: 40,
            },
            {
                label: "SUCCEED",
                data: succeedData,
                backgroundColor: "#6F5DCB",
                borderRadius: 10,
                barThickness: 40,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: "right",
                align: 'end',
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) =>
                        `${context.dataset.label}: ${context.raw}`,
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Remove vertical grid lines
                },
                categoryPercentage: 0.8,
                barPercentage: 0.6,
            },
            y: {
                grid: {
                    display: false, // Remove horizontal grid lines
                },
                beginAtZero: true,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default BarChart2;