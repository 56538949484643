import DatePicker from "react-datepicker";
import SelectInput from "../../../../components/inputs/SelectInput";
import TextInput from "../../../../components/inputs/TextInput";


const statues = [
    { "text": "FAILED", "value": "FAILED" },
    { "text": "SUCCEED", "value": "SUCCEED" },
]

const dateFilterTypes = [
    { "text": "Range Filter", "value": "range" },
    { "text": "Last N-Days", "value": "lastN" },
]

export default function CustomFilters({ data, useCases, dateType, handleOnChooseCheckpoints, handleOnInputChange, handleOnSelectvoiceAgent, conversationStatuses, handleOnSelectConversationStatus, checkpoints, handleOnDateChange, startDate, endDate, handleOnChangeDateType }) {


    return (
        <div className="w-full">
            <div className="grid grid-cols-2 gap-4">
                <SelectInput label='Voice Agent' title='Voice Agent' id="usecase" name="usecase" options={useCases} value={data.usecase} onChange={handleOnSelectvoiceAgent} />
                {(data.type === "calls") &&
                    <SelectInput label='Call Status' title='All' options={statues} disabledTitle={false} id="subStatus" name="subStatus" value={data.subStatus} onChange={handleOnInputChange} />
                }
                {(data.type === "conversations" && data.usecase !== '') &&
                    <SelectInput label='Conversation Status' title='All' options={conversationStatuses} disabledTitle={false} id="conversationStatus" name="conversationStatus" value={data.conversationStatus} onChange={handleOnSelectConversationStatus} />
                }
            </div>

            {(data.type === "conversations" && data.usecase !== '' && data.conversationStatus !== '' && checkpoints.length > 0) &&
                <div className="flex space-x-2 flex-wrap space-y-1 mb-5 ">
                    {checkpoints.map((checkpoint) => (
                        <CheckboxChip
                            key={checkpoint.value}
                            checkpoint={checkpoint}
                            isSelected={data.checkpoints.length > 0 ? data.checkpoints.includes(checkpoint.value) : false}
                            onClick={() => handleOnChooseCheckpoints(checkpoint.value)}
                        />
                    ))}
                </div>
            }


            <div className="flex space-x-5">
                <SelectInput title='Date Filter' options={dateFilterTypes} id="dateFilter" value={dateType} onChange={handleOnChangeDateType} />
                {dateType === "lastN" ?
                    <TextInput type="number" id="lastN" name="lastN" placeholder="Last N-Days" onChange={handleOnInputChange} value={data.lastN} />
                    :
                    <DatePicker
                        dateFormat="dd-MM-yyyy"
                        onChange={handleOnDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Date Range"
                        className="w-full h-11 px-4 py-2 mt-2 rounded-lg  border-gray-300 
                     focus:ring-1  focus:ring-primary focus:border-primary
                     shadow-sm bg-input placeholder-gray-400 
                     text-gray-900 focus:outline-none transition duration-300 ease-in-out" />
                }
            </div>

        </div>

    );
}

const CheckboxChip = ({ checkpoint, isSelected, onClick }) => (
    <div className={`px-4 py-1 rounded-full cursor-pointer m-1  ${isSelected ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'}`} onClick={onClick}>
        {checkpoint.label}
    </div>
);
