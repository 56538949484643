


export default function Table({ children, className }) {
    return (
        <div className={`relative overflow-x-auto rounded-lg border shadow-sm py-6 ${className}`}>
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">
                {children}
            </table>
        </div>
    )
}