


const counterColor = (inprogress, maxConcurrent) => {
    if (inprogress === 0) {
        return "text-gray-500"
    }

    if (inprogress / maxConcurrent * 50 > 37.5) {
        return "text-red-500"
    }

    if (inprogress / maxConcurrent * 50 < 37.5) {
        return "text-orange-500"
    }
}



export default function LiveBoard({ maxConcurrent, counting }) {
    return (
        <div className="col-span-1 flex flex-col  space-y-5 grow">
            <div className="rounded-lg bg-gray-100 shadow-lg h-full border">
                <div className="flex items-center float-right mt-4 mr-4">
                    <span className="relative flex h-3 w-3 mr-2">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-400"></span>
                    </span>
                    <span className="text-red-400 animate-pulse font-bold">Live</span>
                </div>
                <div className=" px-5 pt-5 flex flex-col justify-center items-center space-y-4 clear-right">
                    <p className="text-sm text-gray-500">In Progress / <span className="ml-1 font-bold"> Max CC ({maxConcurrent})</span></p>
                    <div className="relative size-32">
                        <svg className="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-neutral-700" strokeWidth="1" strokeDasharray="50 100" strokeLinecap="round"></circle>
                            <circle cx="18" cy="18" r="16" fill="none" className={`stroke-current ${counterColor(counting.progress, maxConcurrent)} animate-pulse`} strokeWidth="1" strokeDasharray={`${counting.progress === 0 ? 0 : counting.progress / maxConcurrent * 50} 100`} strokeLinecap="round"></circle>
                        </svg>
                        <div className="absolute top-9 start-1/2 transform -translate-x-1/2 text-center">
                            <span className={`text-3xl font-bold ${counterColor(counting.progress, maxConcurrent)} `}>{counting.progress}    </span>
                            <span className={`text-sm ${counterColor(counting.progress, maxConcurrent)} block `}>Progress</span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="rounded-lg bg-white shadow-lg p-5 flex justify-center space-x-10 items-center  h-full border">

                <div className={`text-center `}>
                    <p className="text-sm text-gray-500">In Queue</p>
                    <p className="text-xl font-semibold text-gray-800">{counting.idle} </p>
                </div>

                <div className={`text-center`}>
                    <p className="text-sm text-gray-500">Active Job Orders</p>
                    <p className="text-xl font-semibold text-gray-800">{counting.activeJobOrders} </p>
                </div>

                <div className={`text-center `}>
                    <p className="text-sm text-gray-500">In Pending</p>
                    <p className="text-xl font-semibold text-gray-800">{counting.pendings} </p>
                </div>
            </div>
        </div>
    )
}