import { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout";
import { AuthContext } from "../../store/auth_context";
import { formatDateDDMMYY } from "../../utils/formatting";
import { getAllUseCasesAPI } from "../../services/apis/useCases";
import { constructQueryParams } from "../../utils/encodeURI";
import { ReadCalls, ReadSucceedcalls, updateMany } from "../../services/apis/calls";
import Loading from "../../components/Loading";
import SelectInput from "../../components/inputs/SelectInput";
import DatePicker from "react-datepicker";
import Table2 from "../../components/tables/Table2";
import NoData from "../../components/NoData";
import PedningTable from "../../components/tables/PedningTable";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import { Slide, toast, ToastContainer } from "react-toastify";


const types = [
    { text: "All", value: "All" },
    { text: "Execution", value: "Execution" },
    { text: "Testing", value: "Testing" }
]


const statuses = [
    { text: "Succeed", value: "succeed" },
    { text: "Pending", value: "pending" },
    { text: "Cancelled", value: "cancelled" }
]

//# lang, callid, target, trials, duration, date&time, callstatus, conversation status
export default function Calls() {


    const { profile } = useContext(AuthContext)
    const [calls, setCalls] = useState({ data: [], total: 0, count: 0 });
    const [voiceAgents, setVoiceAgents] = useState([])
    const [conversationStatuses, setConversationStatuses] = useState([])
    const [filters, setFilters] = useState({ status: 'succeed', voiceAgent: '', type: '', conversationStatus: '', gte: '', lte: '', page: 1, limit: 10 })
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenExecute, setIsOpenExecute] = useState(false)

    const paginate = (page) => {
        setFilters((previous) => { return { ...previous, page: page } })
    }

    const limitation = (limit) => {
        setFilters((previous) => { return { ...previous, limit: limit } })
    }

    const handleOnDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setFilters((previous) => {
                return {
                    ...previous,
                    gte: formatDateDDMMYY(start),
                    lte: formatDateDDMMYY(end)
                }
            })
        }
    }


    const handleOnInputChange = (event) => {
        if (event.target.name === "voiceAgent") {
            const voiceAgent = voiceAgents.find((field) => field._id === event.target.value)
            if (voiceAgent.checkpoints && voiceAgent.checkpoints.length > 0) {
                setConversationStatuses(Array.from(new Map(voiceAgent.checkpoints.map(item => [item.status, { text: item.status, value: item.status }])).values()))
            }
        }

        if (event.target.name === "status" && event.target.value !== "succeed") {
            setFilters((previous) => {
                return {
                    ...previous,
                    page: 1,
                    conversationStatus: '',
                    [event.target.name]: event.target.value
                }
            })
        } else {
            setFilters((previous) => {
                return {
                    ...previous,
                    page: 1,
                    [event.target.name]: event.target.value
                }
            })
        }


    }

    const voiceAgentsOptions = useMemo(() => {
        if (voiceAgents.length > 0) {
            return voiceAgents.map((usecase) => {
                return { text: usecase.name, value: usecase._id }
            })
        }
        return []
    }, [voiceAgents]);


    const headers = useMemo(() => {
        if (filters.voiceAgent !== '' && filters.status === "succeed") {
            return ["lang", "callId", "Target", "trials", "Duration", "Date & Time", "Call Status", "Conversation Status"]
        } else {
            return ["lang", "callId", "Target", "trials", "Duration", "Date & Time", "Call Status"]
        }

    }, [filters.voiceAgent, filters.status]);


    const retrieveConversationStatus = (call, usecase) => {
        if (usecase && usecase.checkpoints && usecase.checkpoints.length > 0) {
            const matchingCheckpoint = usecase.checkpoints.find((checkpoint) => checkpoint.id === call.checkPoint);
            if (matchingCheckpoint) {
                console.log("checkpoint.status", matchingCheckpoint.status);
                return matchingCheckpoint.status; // Return the status of the matching checkpoint
            }
        }
        return "-"; // Return "-" if no matching checkpoint is found
    }

    const processingDataForTable = (data) => {
        //# lang, callid, target, trials, duration, date&time, callstatus, conversation status
        //callDuration to Duraition
        //created_on to date and time
        //subStatus to call status

        let usecase
        if (filters.voiceAgent) {
            usecase = voiceAgents.find((voiceAgent) => voiceAgent._id === filters.voiceAgent)
            console.log('usecase', usecase)
        }

        const mappedData = data.map(item => ({
            _id: item._id,
            lang: item.lang,
            callId: item.callId,
            Target: item.metadata ? item.metadata.target ? item.metadata.target : "-" : "-",
            trials: item.trials,
            Duration: item.callDuration,
            "Date & Time": item.created_on,
            "Call Status": item.subStatus,
            "Conversation Status": retrieveConversationStatus(item, usecase)
        }));

        return mappedData
    }


    const [selectedRows, setSelectedRows] = useState([]);
    const allChecked = selectedRows.length === calls.data.length;


    const handleSelectAll = () => {
        console.log('allChecked', allChecked)
        setSelectedRows(allChecked ? [] : calls.data.map((item) => item._id));
    };

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((rowId) => rowId !== id)
                : [...prevSelected, id]
        );
    };


    const handleOnCancel = async (event) => {

        console.log('calls', selectedRows)
        updateMany({ 'calls': selectedRows, is_cancelled: new Date() }).then((response) => {
            toast.success("Calls Cancelled Successfully!", { transition: Slide })
            setFilters({ status: 'pending', voiceAgent: '', type: '', conversationStatus: '', gte: '', lte: '', page: 1, limit: 10 })
        }).catch((error) => {
            toast.error(error.message, { transition: Slide })
        })
    }

    const handleOnSubmit = async (event) => {
        updateMany({ 'calls': selectedRows, is_pending: false, subStatus: "IDLE", status: "IN_QUEUE" }).then((response) => {
            toast.success("Execute Pending Calls Successfully!", { transition: Slide })
            setFilters({ status: 'pending', voiceAgent: '', type: '', conversationStatus: '', gte: '', lte: '', page: 1, limit: 10 })
        }).catch((error) => {
            toast.error(error.message, { transition: Slide })
        })
    }

    useEffect(() => {
        getAllUseCasesAPI(profile.organization._id).then((response) => {
            if (response && response.data) {
                setVoiceAgents(response.data.usecases)
            }
        }).catch((error) => {
            console.log('error', error)
        })
    }, [profile.organization._id])


    useEffect(() => {
        setLoading(true)
        const query = constructQueryParams(filters);
        ReadCalls(profile.organization._id, query).then((response) => {
            setLoading(false)
            setCalls((previous) => {
                return ({
                    ...previous,
                    data: processingDataForTable(response.calls),
                    total: response.total,
                    count: response.count
                })
            })
            console.log('query', query)
            console.log('data', response)
        }).catch((error) => {
            setLoading(false)
        })
        console.log('query', query)
    }, [filters, profile.organization._id])

    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    return (
        <Layout title='Calls'>
            <div className="flex space-x-4 justify-end ">
                <SelectInput title="Report Type" options={statuses} value={filters.status} onChange={handleOnInputChange} name="status" />
                <SelectInput title="Type" options={types} value={filters.type} onChange={handleOnInputChange} name="type" />
                <SelectInput title="Voice Agent" options={voiceAgentsOptions} value={filters.voiceAgent} onChange={handleOnInputChange} name="voiceAgent" />

                {filters.voiceAgent && filters.status === "succeed" &&
                    <SelectInput title="Conversation Status" options={conversationStatuses} value={filters.conversationStatus} name="conversationStatus" onChange={handleOnInputChange} />
                }

                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    onChange={handleOnDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText="Date Range"
                    className="w-full h-11 px-4 py-2 mt-2 rounded-lg  border-gray-300 
                     focus:ring-1  focus:ring-primary focus:border-primary
                     shadow-sm bg-input placeholder-gray-400 
                     text-gray-900 focus:outline-none transition duration-300 ease-in-out" />
                <button className=" h-11 px-4 py-2 mt-2 rounded-lg shadow-sm bg-primary-600 text-white font-inter">Export</button>


                {profile.role === "monitor" ? undefined :
                    selectedRows.length > 0 &&
                    <>
                        <button className=" h-11 px-4 py-2 mt-2 rounded-lg shadow-sm bg-primary-600 text-white font-inter" onClick={() => setIsOpenExecute(true)}>Execute</button>
                        <button className=" h-11 px-4 py-2 mt-2 rounded-lg shadow-sm bg-red-400 text-white font-inter" onClick={() => setIsOpen(true)}>Cancel</button>
                    </>}
            </div>

            {calls.data.length > 0 ?
                filters.status === "pending" ?
                    <PedningTable data={calls} filters={filters} paginate={paginate} limitation={limitation} header={headers} allChecked={allChecked} handleSelectAll={handleSelectAll} selectedRows={selectedRows} handleSelectRow={handleSelectRow} />
                    :
                    <Table2 data={calls} filters={filters} paginate={paginate} limitation={limitation} header={headers} questions={[]} />
                :
                <NoData />
            }


            <ConfirmDialog eventFunction={() => { handleOnSubmit(); }} isOpen={isOpenExecute} setIsOpen={setIsOpenExecute} title="Execute Pending Calls" description="Are you sure you want to execute selected calls?" />

            <ConfirmDialog eventFunction={() => { handleOnCancel(); }} isOpen={isOpen} setIsOpen={setIsOpen} title="Cancel Calls" description="Are you sure you want to cancel selected calls?" />

            <ToastContainer />
        </Layout>
    )
}

// const transformedData = useMemo(() => {
//     return data.map(item => ({
//       Name: item.firstName + " " + item.lastName,
//       Age: item.age,
//     }));
//   }, [data]);