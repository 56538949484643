import { useState } from "react";
import DatePickerInput from "../../components/inputs/DatePickerInput";
import SelectInput from "../../components/inputs/SelectInput";
import { formatDateDDMMYY } from "../../utils/formatting";

const types = [
    { text: "All", value: "all" },
    { text: "Testing", value: "simulation" },
    { text: "Execution", value: "execution" }
]

export default function Filters({ filters, setFilters }) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleOnDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setFilters((previous) => {
                return {
                    ...previous,
                    gte: formatDateDDMMYY(start),
                    lte: formatDateDDMMYY(end)
                }
            })
        }
    }

    const handleSelectChange = (event) => {
        console.log('event.target.id', event.target.id, 'event.target.value', event.target.value)
        if (event.target.id === "type" && event.target.value === "simulation") {
            setFilters({ ...filters, active: true, [event.target.id]: event.target.value, orderRequest: '' })
        } else {
            setFilters({ ...filters, active: true, [event.target.id]: event.target.value })
        }
    }

    return (
        <div className="flex space-x-5 flex-wrap space-x-0 mb-4 lg:space-x-5 lg:mb-0 md:space-x-5 md:mb-0 w-full">
            <SelectInput name="usecase" title='Voice Agent' options={[]} value={filters.usecase} id='usecase' onChange={handleSelectChange} />
            <SelectInput name="type" title='Type' options={types} value={filters.type} id='type' onChange={handleSelectChange} />
            <DatePickerInput handleOnDateChange={handleOnDateChange} startDate={startDate} endDate={endDate} />
        </div>
    )
}