import moment from "moment"
import Pagination from "../Pagination"



export default function Table2({ header, data, filters, paginate, limitation }) {
    return (
        <div className="relative overflow-x-auto  rounded-xl border  shadow-sm py-6">
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">

                <thead className="text-xs text-primary uppercase  h-12 ">
                    <tr className="border-b border-gray-200   font-inter text-center">
                        <th scope="col" className="font-semibold px-6 py-3 w-10 " > #</th>
                        {header.map((item, index) => {
                            return (
                                <td key={index} className="px-6 py-3  whitespace-nowrap" >{item}</td>
                            )
                        })}

                    </tr>
                </thead>

                <tbody>
                    {data.data.map((row, rowIndex) => {
                        return (
                            <tr key={rowIndex} className={`${rowIndex >= data.length - 1 ? undefined : 'border-b border-gray-200'} font-inter h-20 text-center`}>
                                <th scope="col" className="font-semibold px-6 py-3 w-10 " >{rowIndex + 1}</th>
                                {header.map((col, colIndex) => (
                                    <td key={colIndex} className="px-6 py-3 align-middle" >{col === "Date & Time" ? moment(row[col]).format('DD-MM-YYYY') : row[col]}</td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
            </table>


            {paginate && filters &&

                <Pagination total={data.total} limit={filters.limit} paginate={paginate} limitation={limitation} />
            }

        </div>
    )
}