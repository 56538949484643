import { useState } from "react";

const limits = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 40 },
    { text: '100', value: 100 },
]

export default function Pagination({ total, limit, paginate, limitation }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(total / limit); i++) {
        pageNumbers.push(i);
    }

    const handleOnLimitation = (event) => {
        limitation(event.target.value)
    }

    return (
        <nav className="flex justify-start pt-10">
            <select className="flex-none bg-white px-2 py-2 border rounded-lg font-inter text-center mx-4" onChange={handleOnLimitation} value={limit}>
                {limits.map((item) => (<option key={item.value} className="" value={item.value}>{item.value}</option>))}

            </select>
            <p className="flex-none text-gray-500 self-center">Showing {limit >= total ? total : limit} items of {total}</p>


            <ul className="inline-flex space-x-1 grow justify-end mr-5">
                {pageNumbers.map(number => (
                    <li key={number}>
                        <button
                            onClick={() => paginate(number)}
                            className="px-3 py-2 ml-0 leading-tight rounded-lg  font-inter bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};