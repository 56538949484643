import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { ExclamationIcon } from '@heroicons/react/outline'

export default function BatchDetailsModal({ open, setOpen, data, }) {
    if (!open) return null

    // Extract headers dynamically from the first object in the array
    const headers = Object.keys(data[0]);
    return (

        <>

            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  ">
                <div className="relative w-1/2 my-6">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-2">

                        <div className="flex justify-between px-12 mt-4">
                            <h1 className="text-lg font-inter font-semibold text-primary">Total ({data.length})</h1>
                            <button onClick={() => setOpen(false)} className="py-2 px-8 rounded-xl shadow-sm   text-black  float-right text-md bg-white border border-red-400 text-red-400 border-1 hover:bg-red-400 hover:text-white leading-none font-semibold outline-none focus:outline-none">
                                Close
                            </button>
                        </div>
                        <div className=" w-full h-full">

                            <div className="relative overflow-x-auto mb-4 px-8 py-4 rounded-lg  shadow-sm">

                                <div className="max-h-[calc(100vh-12rem)] overflow-y-auto">
                                    <table className="min-w-full text-sm text-left rtl:text-right text-gray-900">
                                        <thead className="text-xs text-primary uppercase sticky top-0 bg-gray-200   ">
                                            <tr className="border-b border-gray-400   font-inter">
                                                <th scope="col" className="font-semibold px-6 py-3 w-10 " > #</th>
                                                {headers.map((item, index) => {
                                                    return (<td key={index} className="px-6 py-3 " >{item}</td>
                                                    )
                                                })}

                                            </tr>
                                        </thead>

                                        <tbody>

                                            {data.map((item, rowIndex) => (
                                                <tr key={rowIndex} className={`${rowIndex >= data.length - 1 ? undefined : 'border-b border-gray-200'} font-inter h-20`}>
                                                    {/* Dynamically render table rows */}
                                                    <th scope="col" className="font-semibold px-6 py-3 w-10 " >{rowIndex + 1}</th>
                                                    {headers.map((header, colIndex) => (
                                                        <td key={colIndex} className="font-semibold px-6 py-3 w-10">
                                                            {item[header]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>



                                    </table>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>


    );
}


