import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { AuthContext } from "../store/auth_context";
import CancelledCallsTable from "../components/CancelledCallsTable";
import NoData from "../components/NoData";
import { ReadCancelledCalls } from "../services/apis/calls";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import Table from "../components/tables/Table";
import { constructQueryParams } from "../utils/encodeURI";
import Loading from "../components/Loading";


export default function CancelledCalls() {
    const { profile } = useContext(AuthContext)
    const [calls, setCalls] = useState({ data: [], total: 0, count: 0 });
    const [filters, setFilters] = useState({ limit: 10, page: 1 })
    const [loading, setLoading] = useState(false)


    const handleExportCSV = () => {
        const csv = Papa.unparse(calls);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'data.csv');
    };

    const paginate = (page) => {
        setFilters((previous) => { return { ...previous, page: page } })
        console.log('pagination', filters)
    }

    const limitation = (limit) => {
        setFilters((previous) => { return { ...previous, limit: limit } })
    }

    useEffect(() => {
        setLoading(true)
        const query = constructQueryParams(filters);
        console.log('query', query)
        ReadCancelledCalls(profile.organization._id, query).then((response) => {
            setCalls((previous) => {
                return ({
                    ...previous,
                    data: response.calls,
                    total: response.total,
                    count: response.count
                })
            })

            console.log('response', calls)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }, [profile.organization._id, filters])

    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    return (
        <Layout title="Cancelled Calls">
            <button className="mb-4 bg-primary h-10 w-20 rounded-lg text-white shadow-sm mt-4" onClick={handleExportCSV}>Export</button>

            {calls.data.length > 0 ?
                <Table data={calls} filters={filters} paginate={paginate} limitation={limitation} header={["lang", "sid", "callId", "trials", "status", "subStatus", "reason", "reasonCode", "callDuration"]} questions={[]} />
                :
                <NoData />
            }

        </Layout>
    )
}