import React, { useEffect, useState } from "react";
import moment from "moment";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TrashIcon } from "@heroicons/react/24/outline";

import {
  getAllUsersAPI,
  createUserAPI,
  deleteUserAPI,
} from "../services/apis/usersManagement";

import Layout from "../components/Layout";
import DeleteModal from "../components/DeleteModal";

export default function UserManagement() {
  const roleOptions = [
    { name: "Full Access", value: "full_access" },
    { name: "Monitor Access", value: "monitor_access" },
    { name: "Tester", value: "tester" },
  ];
  const [users, setusers] = useState([]);
  const [user, setuser] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
  });
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [selectedUserId, setselectedUserId] = useState("");

  async function create_new_user() {
    createUserAPI(user)
      .then((res) => {
        toast.success("User Added Successfully!", {
          transition: Slide,
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setuser({
          name: "",
          email: "",
          password: "",
          role: "",
        });

        get_all_users();
      })
      .catch((err) => console.log(err));
  }

  async function delete_user() {
    deleteUserAPI(selectedUserId)
      .then((res) => {
        toast.success("User Deleted Successfully!", {
          transition: Slide,
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        get_all_users();
      })
      .catch((err) => console.log(err));
  }

  async function get_all_users() {
    getAllUsersAPI()
      .then((res) => {
        setusers(res.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    get_all_users();
  }, []);

  return (
    <Layout title='User Management'>



      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5 shadow-lg p-12 bg-white  mt-12 rounded-lg ">
        <div>
          <label className="block text-sm leading-6 text-gray-600 capitalize font-inter">
            Name
          </label>
          <input
            type="text"
            placeholder="John doe"
            name="name"
            id="name"
            value={user.name}
            onChange={(e) => {
              setuser({ ...user, [e.target.name]: e.target.value });
            }}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 font-inter shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 h-10 p-2 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div>
          <label className="block text-sm leading-6 text-gray-600 capitalize">
            Email
          </label>
          <input
            type="text"
            placeholder="john@alzubda.com"
            name="email"
            id="email"
            value={user.email}
            onChange={(e) => {
              setuser({ ...user, [e.target.name]: e.target.value });
            }}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 h-10 p-2 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div>
          <label className="block text-sm leading-6 text-gray-600 capitalize">
            Password
          </label>
          <input
            type="password"
            placeholder="*********"
            name="password"
            id="password"
            value={user.password}
            onChange={(e) => {
              setuser({ ...user, [e.target.name]: e.target.value });
            }}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 font-inter shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 h-10 p-2 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>

        <div>
          <label className="block text-sm leading-6 text-gray-600 capitalize">
            Access Type
          </label>

          <select
            id="role"
            name="role"
            value={user.role}
            onChange={(e) => {
              setuser({ ...user, [e.target.name]: e.target.value });
            }}
            className="block w-full rounded-md border-0 py-2.5 text-gray-900 font-inter bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 h-10 p-2 focus:outline-none focus:ring-2 focus:ring-primary"
          >
            <option value="" disabled selected>
              Select
            </option>
            {roleOptions.map((role, idx) => {
              return (
                <option key={idx} value={role.value}>
                  {role.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex">
          <button
            onClick={() => create_new_user()}
            type="button"
            className="place-self-end py-2 px-12 text-sm font-medium rounded-md text-white bg-primary font-inter shadow-sm h-10 "
          >
            Create user
          </button>
        </div>
      </div>

      <br />

      <Table
        columns={["Name", "Email", "Access Type", "Date", ""]}
        data={users}
        setopenDeleteModal={setopenDeleteModal}
        setselectedUserId={setselectedUserId}
      />

      <DeleteModal
        open={openDeleteModal}
        setOpen={setopenDeleteModal}
        deleteFunc={() => {
          delete_user();
        }}
      />
      <ToastContainer />
    </Layout>
  );
}

const Table = ({ columns, data, setopenDeleteModal, setselectedUserId }) => {
  return (
    <div className="relative overflow-x-auto mb-4 bg-white my-8 px-5 py-8 rounded-lg border shadow-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
        <thead className="text-xs text-primary uppercase bg-white  ">
          <tr className="border-b border-gray-700 text-center font-inter">
            {columns.map((col, idx) => {
              return (
                <th key={idx} className="border-b border-gray-300 py-3 px-4">
                  {col}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr className="bg-white border-b border-gray-400 text-center font-inter">

              <td className="w-52 border-b border-gray-300 p-4 text-center">
                {item?.name}
              </td>

              <td className="border-b border-gray-300 p-4 text-center">
                {item?.email}
              </td>
              <td className="border-b border-gray-300 p-4 text-center">
                {item?.role === "full_access"
                  ? "Full Access"
                  : item?.role === "monitor_access"
                    ? "Monitor Access"
                    : item.role}
              </td>
              <td className="border-b border-gray-300 p-4 text-center">
                {moment(item?.created_on).format("DD/MM/YYYY")}
              </td>
              <td
                onClick={() => {
                  setopenDeleteModal(true);
                  setselectedUserId(item._id);
                }}
                className="border-b border-gray-300 text-center"
              >
                <TrashIcon className="text-red-600 h-6 w-6 mx-auto cursor-pointer" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
