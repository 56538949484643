import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"



function WorkspacesTableItem({ workspace, index, setOpenDeleteDialog, setSelectedWorkspace, setOpenUpdateDialog, setSelectedWorkspaceProfile }) {

    const onRemove = (id) => {
        setOpenDeleteDialog(true)
        setSelectedWorkspace(id)
    }

    const onUpdate = (index, workspace) => {
        setOpenUpdateDialog(true)
        setSelectedWorkspace(index)
        setSelectedWorkspaceProfile(workspace)
    }

    return (
        <tr>
            <td className="p-4 border-b border-gray-200">
                <p className="block font-inter text-sm  font-normal leading-normal ">{index + 1}</p>
            </td>
            <td className="p-4 border-b border-gray-200">
                <p className="block font-inter text-sm  font-normal leading-normal ">{workspace.workspace_name}</p>
            </td>
            <td className="p-4 border-b border-gray-200">
                <p className="block font-inter text-sm  font-normal leading-normal ">{workspace.botId}</p>
            </td>
            <td className="p-4 border-b border-gray-200 text-center flex justify-center">
                <div className="flex space-x-4 justify-center bg-gray-200 rounded-xl py-1 shadow-sm w-3/4">
                    <PencilSquareIcon className="h-6 w-6 text-primary-500 cursor-pointer hover:text-primary-600 transition duration-300 ease-in-out" onClick={() => onUpdate(index, workspace)} />
                    <TrashIcon className="text-red-500 h-6 w-6 mx-auto cursor-pointer hover:text-red-600 transition duration-300 ease-in-out" onClick={() => onRemove(index)} />
                </div>
            </td>
        </tr>
    )
}


export default function WorkspacesTable({ workspaces, setOpenDeleteDialog, setSelectedWorkspace, setOpenUpdateDialog, setSelectedWorkspaceProfile }) {
    return (
        <div className="rounded-lg shadow-sm">
            <table className="w-full text-left table-auto min-w-max">
                <thead className="font-inter text-primary-700 font-semibold text-md">
                    <tr>
                        <th className="p-4 border-b border-gray-300 bg-blue-gray-50/50 text-start">
                            <p className="block font-inter text-sm  font-normal leading-none  opacity-70">
                                #
                            </p>
                        </th>
                        <th className="p-4 border-b border-gray-300 bg-blue-gray-50/50 text-start">
                            <p className="block font-inter text-sm  font-normal leading-none  opacity-70">
                                Workspace Name
                            </p>
                        </th>

                        <th className="p-4 border-b border-gray-300 bg-blue-gray-50/50">
                            <p className="block font-inter text-sm  font-normal leading-none  opacity-70">
                                Bot Id
                            </p>
                        </th>

                        <th className="p-4 border-b border-gray-300 bg-blue-gray-50/50 text-center">
                            <p className="block font-inter text-sm  font-normal leading-none  opacity-70">
                                Actions
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {workspaces.map((workspace, index) => {
                        return (
                            <WorkspacesTableItem workspace={workspace} index={index} key={index} setOpenDeleteDialog={setOpenDeleteDialog} setSelectedWorkspace={setSelectedWorkspace} setOpenUpdateDialog={setOpenUpdateDialog} setSelectedWorkspaceProfile={setSelectedWorkspaceProfile} />
                        )
                    })}

                </tbody>
            </table>
        </div>

    )
}