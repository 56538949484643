import { PhotoIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import Layout from "../components/Layout";
import TextInput from "../components/inputs/TextInput";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../store/auth_context";
import { readOne, update } from "../services/apis/organization";
import Loading from "../components/Loading";
import { read, update as updateMaxConcurrent } from "../services/apis/systemConfigs";
import { Slide, toast, ToastContainer } from "react-toastify";



export default function Profile() {
    const { profile, changeLogo } = useContext(AuthContext)
    const [image, setImage] = useState('https://flitc.ai/assets/web/images/logo.png');
    const [maxConcurrent, setMaxConcurrent] = useState(1)
    const [settings, setSettings] = useState()
    const [selectedFile, setSelectedFile] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [botIds, setBotIds] = useState([]);
    const [loading, setLoading] = useState(true)
    const [department, setDepartment] = useState('')
    const [botId, setBotId] = useState('')
    const [enviornment, setEnvironment] = useState('development')


    const fileInputRef = useRef(null)

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setImage(URL.createObjectURL(file));
        }
    };

    const handleOnInputChange = (event) => {
        if (event.target.name === "departments") {
            setDepartment(event.target.value)
        }

        if (event.target.name === "botIds") {
            setBotId(event.target.value)
        }

        if (event.target.name === "maxConcurrent") {
            setMaxConcurrent(event.target.value)
        }
    }

    const handleOnAddDepartment = () => {
        if (department.trim() === '') return;
        setDepartments((previous) => [...previous, department])
        setDepartment('')
    }

    const handleOnAddBotId = () => {
        if (botId.trim() === '') return;
        setBotIds((previous) => [...previous, botId])
        setBotId('')
    }

    const handleOnRemoveDepartment = (item) => {
        setDepartments(departments.filter((department, index) => index !== item))
    }

    const handleOnRemoveBotId = (item) => {
        setBotIds(botIds.filter((botId, index) => index !== item))
    }

    const handleOnSave = async () => {
        try {
            const formData = new FormData();
            if (selectedFile) {
                formData.append('file', selectedFile);
            }

            if (departments.length > 0) {
                departments.forEach(department => {
                    formData.append('departments', department);
                });
            } else {
                formData.append('departments', '[]');
            }

            if (botIds.length > 0) {
                botIds.forEach(botId => {
                    formData.append('botIds', botId);
                });
            } else {
                formData.append('botIds', '[]');
            }

            formData.append('max_concurrent_calls', maxConcurrent);
            setLoading(true)
            await updateMaxConcurrent(profile.organization._id, { max_concurrent_calls: maxConcurrent })
            const response = await update(profile.organization._id, formData)
            if (selectedFile) {
                changeLogo(response.logo)
            }
            setLoading(false)
            toast.success("Profile Updated Successfully!", { transition: Slide })
        } catch (error) {
            console.log('error', error)
        }
    }


    useEffect(() => {
        setLoading(true)
        readOne(profile.organization._id).then((response) => {
            setSettings(response)
            setLoading(false)
            setDepartments(response.departments && response.departments.length > 0 ? response.departments : [])
            setBotIds(response.botIds && response.botIds.length > 0 ? response.botIds : [])
            setImage(response.logo ? response.logo : '')
            setEnvironment(response.environment)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })
    }, [profile.organization._id])

    useEffect(() => {
        read(profile.organization._id).then((response) => {
            console.log('max', response)
            setMaxConcurrent(response.max_concurrent_calls)
        }).catch((error) => {
            console.log('error', error)
        })
    }, [profile.organization._id])


    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    return (
        <Layout title='Settings'>

            <div className="bg-white p-8 mb-4 border shadow-lg rounded-lg pb-24">

                <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                    Settings
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4  mb-4 w-3/4">
                    This is your profile page where you can update your profile logo, add departments, and configure bot IDs. You can also set the maximum number of concurrent actions here.
                </p>


                <div className=" border w-48 rounded-full h-48 p-4 mt-5 flex justify-center items-center relative float-right mr-10 ">
                    <img className="w-full h-full object-contain transition-all duration-300 " src={image} alt="..." />
                    <div onClick={handleButtonClick} className="cursor-pointer absolute inset-0 bg-black bg-opacity-25 opacity-100 hover:opacity-25 transition-all duration-300 flex items-center justify-center rounded-full">
                        <PhotoIcon className="text-white text-xl hover:text-gray-200 transition duration-200 w-10" />
                    </div>
                </div>

                <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />


                {settings.name &&
                    <p className="w-full lg:w-1/4 text-secondary text-lg font-semibold leading-6">{settings.name}</p>
                }


                <p className="w-full lg:w-1/4 text-secondary text-md font-semibold leading-6">{enviornment.charAt(0).toUpperCase() + enviornment.slice(1)} Environment</p>

                {settings.conversation_ai_platform &&
                    <p className="w-full lg:w-1/4 text-secondary text-md font-semibold leading-6">{settings.conversation_ai_platform}</p>
                }

                <div className="w-full lg:w-1/4 mt-4 mb-4">
                    <TextInput label="Max Concurrent" min="1" max={`${enviornment === "production" ? 50 : 5}`} type="number" id="maxConcurrent" name="maxConcurrent" placeholder="Max Concurrent" value={maxConcurrent} onChange={handleOnInputChange} />
                </div>


                <div className="flex space-x-2 my-auto">
                    <TextInput label="Department" placeholder="Call Center" id="departments" name="departments" value={department} onChange={handleOnInputChange} />
                    <PlusCircleIcon className="h-5 w-5 my-auto text-green-700 mb-6 cursor-pointer" onClick={() => handleOnAddDepartment()} />
                </div>

                {departments.length > 0 ?
                    <ul className="space-y-2 mb-4">
                        {departments.map((item, index) => {
                            return (
                                <li key={index} className="flex items-center justify-start rounded-lg space-x-2">
                                    <div className="w-3/4">
                                        <TextInput disabled value={item} />
                                    </div>

                                    <TrashIcon className="h-5 w-5 my-auto text-red-700 mb-6 cursor-pointer" onClick={() => handleOnRemoveDepartment(index)} />
                                </li>
                            )

                        })}
                    </ul>
                    : undefined}


                <div className="flex space-x-2 my-auto">
                    <TextInput label="BotId" placeholder="st-56e8c25d-ac3e-5e2b-b8d7-40096c1e8e36" id="botIds" name="botIds" value={botId} onChange={handleOnInputChange} />
                    <PlusCircleIcon className="h-5 w-5 my-auto text-green-700 mb-6 cursor-pointer" onClick={() => handleOnAddBotId()} />
                </div>
                {botIds.length > 0 ?
                    <ul className="space-y-2 mb-4">
                        {botIds.map((item, index) => {
                            return (
                                <li key={index} className="flex items-center justify-start rounded-lg space-x-2">
                                    <div className="w-3/4">
                                        <TextInput disabled value={item} />
                                    </div>

                                    <TrashIcon className="h-5 w-5 my-auto text-red-700 mb-6 cursor-pointer" onClick={() => handleOnRemoveBotId(index)} />
                                </li>
                            )

                        })}
                    </ul>
                    : undefined}




                <button className="px-4 py-1 h-10 mt-4 bg-primary rounded-lg text-white w-36 float-right" onClick={() => handleOnSave()}>Save</button>
            </div>

            <ToastContainer />
        </Layout >
    )
}