
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getNavigation } from "./config";
import SidebarGroup from "../../components/sidebar/SidebarGroup";
import SidebarItem from "../../components/sidebar/SidebarItem";




export default function Sidebar() {
    const pages = getNavigation("")
    const location = useLocation()
    const navigate = useNavigate()

    const handleOnLogout = () => {
        localStorage.removeItem('access')
        navigate('/')
    }

    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 shadow-lg py-5 border">

            <div className="flex shrink-0 mx-2 my-2 mb-2">
                <p className='text-5xl text-secondary font-inter font-semibold text-center '>OCM</p>
            </div>

            <nav className="flex flex-1 flex-col my-0">
                <ul className="space-y-2 font-medium">

                    {pages.map((page) => {
                        if (page.items)
                            return (<SidebarGroup key={page.title} title={page.title} icon={page.icon} items={page.items} location={location} />)
                        else
                            return (<SidebarItem key={page.title} title={page.title} icon={page.icon} link={page.link} location={location} />)
                    })}
                </ul>
            </nav>


            <div className='flex flex-col space-y-2'>
                {/* <button className="flex items-center bg-active p-2 px-3 rounded-lg  shadow-sm font-inter shadow-sm">
                    <img src='/icons/fi_headphones.png' alt="Headphone Icon" className="w-5 h-5 mr-2" />
                    Contact Support
                </button> */}

                <button onClick={() => handleOnLogout()} className="flex items-center text-red-400  p-2  hover:bg-default font-inter border-t-2">
                    <img src='/icons/Logout.png' alt="Headphone Icon" className="w-5 h-5 mr-2" />
                    Logout
                </button>
            </div>


        </div>
    )
}