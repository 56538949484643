import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddUserForm from "./AddUserForm";
import Layout from "../../../components/Layout";
import { create, ReadByOrgId, deleteUser as removeUser } from "../../../services/apis/users";
import { AuthContext } from "../../../store/auth_context";
import moment from "moment";
import Table from "../../../components/shared/tables/Table";
import Thead from "../../../components/shared/tables/Thead";
import Tbody from "../../../components/shared/tables/Tbody";
import TableRow from "../../../components/shared/tables/TableRow";
import TableCell from "../../../components/shared/tables/TableCell";

export default function UserManagement2() {

    const { profile } = useContext(AuthContext)
    const [trigger, setTrigger] = useState(0)
    const [users, setusers] = useState([]);
    const [user, setuser] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
    });
    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const [selectedUserId, setselectedUserId] = useState("");

    const headers = ["name", "email", "role", "created_on", "Action"]

    const createUser = async () => {
        user['organization'] = profile.organization._id
        create(user).then((response) => {
            toast.success("User Added Successfully!", { transition: Slide })
            setuser({
                name: "",
                email: "",
                password: "",
                role: "",
            });
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }


    const deleteUser = async () => {
        removeUser(selectedUserId).then((response) => {
            toast.success(response, { transition: Slide })
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }

    useEffect(() => {
        ReadByOrgId(profile.organization._id).then((response) => {
            setusers(response);
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        });
    }, [trigger, profile.organization._id]);


    return (
        <Layout title='User Management'>
            <AddUserForm user={user} setuser={setuser} createUser={createUser} />


            <Table className="mt-5">
                <Thead>
                    <TableRow>
                        <TableCell className="font-semibold">#</TableCell>
                        {headers.map((header, index) => {
                            return (
                                <TableCell className="font-semibold" key={index}>
                                    {header === "created_on" ? "Date" : header}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </Thead>
                <Tbody>
                    {users.map((row, rowIdx) => (
                        <TableRow key={rowIdx}>
                            <TableCell>{rowIdx + 1}</TableCell>
                            {headers.map((col, colIdx) => (
                                <TableCell key={colIdx}>
                                    {col === "created_on" ? moment(row[col]).format('DD-MM-YYYY') : row[col]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </Tbody>
            </Table>

            <ToastContainer />
        </Layout>
    )
}