import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { useContext, useEffect, useState } from "react";
import { getAllUseCasesAPI, deleteUseCase } from "../services/apis/useCases";
import moment from "moment";
import Loading from "../components/Loading";
import { Slide, toast, ToastContainer } from "react-toastify";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import DeleteModal from "../components/DeleteModal";
import { AuthContext } from "../store/auth_context";
import NoData from "../components/NoData";
import TextInput from "../components/inputs/TextInput";
import { read, update } from "../services/apis/systemConfigs";



export default function VoiceAgent() {
    const { profile } = useContext(AuthContext)
    const [voiceAgents, setVoiceAgent] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [maxConcurrent, setMaxConcurrent] = useState(0)
    const [openDeleteModal, setopenDeleteModal] = useState(false)
    const [selectedVoiceAgentId, setselectedVoiceAgentId] = useState("")
    const [trigger, setTrigger] = useState(0)
    const role = profile.role

    // const handleUpdateMaxConcurrent = async () => {
    //     update(profile.organization._id, { max_concurrent_calls: maxConcurrent }).then((response) => {
    //         toast.success("Max Concurrent Updated Successfully!", { transition: Slide })
    //     }).catch((error) => {
    //         console.log('error', error)
    //         toast.error(error.messsage, { transition: Slide })
    //     })
    // }

    useEffect(() => {
        setLoading(true)
        getAllUseCasesAPI(profile.organization._id)
            .then((res) => {
                setVoiceAgent(res.data.usecases);
                setLoading(false)
                setError('')
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
                setError(err)

            });
    }, [trigger, profile.organization._id])


    //handle On Delete Voice Agent
    const deleteVoiceAgent = async () => {
        deleteUseCase(selectedVoiceAgentId).then((response) => {
            toast.success(response, { transition: Slide })
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            toast.error(error.messsage, { transition: Slide })
        })
    }
    // useEffect(() => {
    //     read(profile.organization._id).then((response) => {
    //         setMaxConcurrent(response.max_concurrent_calls)
    //     }).catch((error) => {
    //         toast.error(error.messsage, { transition: Slide })
    //     })
    // }, [profile.organization._id])

    return (
        <Layout title='Voice Agents'>



            {role === "administrator" &&
                <>
                    <div className="flex justify-end items-center space-x-4">
                        {/* <div className="flex space-x-2">
                            <TextInput label="Max Concurrent" type="number" id="maxConcurrent" value={maxConcurrent} name="maxConcurrent" onChange={(event) => setMaxConcurrent(event.target.value)} placeholder="Max Concurrent" required />
                            <button onClick={handleUpdateMaxConcurrent} className="shadow-sm bg-primary px-7 text-white font-semibold mt-4 py-2 self-center rounded-md h-11">Save</button>
                        </div> */}
                        <Link to='/create_voice_agent' className="shadow-sm bg-primary px-5 text-white font-semibold py-2 mt-4 rounded-md  font-inter h-11">Create Voice Agent</Link>
                    </div>

                </>

            }

            {loading ? <Loading /> : voiceAgents.length > 0 ?
                <Table role={role} voiceAgents={voiceAgents} setopenDeleteModal={setopenDeleteModal} setselectedVoiceAgentId={setselectedVoiceAgentId} ></Table>
                : <NoData />
            }

            {error &&
                toast.error(error, {
                    transition: Slide,
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })}


            <DeleteModal
                open={openDeleteModal}
                setOpen={setopenDeleteModal}
                deleteFunc={() => {
                    deleteVoiceAgent();
                }}
            />
            <ToastContainer />
        </Layout>
    )
}

const Table = ({ voiceAgents, setopenDeleteModal, setselectedVoiceAgentId, role }) => {
    return (
        <div className="relative overflow-x-auto mb-4 bg-white my-5 px-5 py-8 rounded-lg border shadow-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
                <thead className="text-xs text-primary uppercase bg-white  ">
                    <tr className="border-b border-gray-700 text-center font-inter">
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Workspace
                        </th>

                        <th scope="col" className="px-6 py-3">
                            Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Priority
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Production
                        </th>
                        <th scope="col" className="px-6 py-3">
                            MaxCC
                        </th>

                        <th scope="col" className="px-6 py-3">
                            Date
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {voiceAgents.map((voiceAgent) => {
                        return (<TableItem role={role} key={voiceAgent._id} voiceAgent={voiceAgent} setselectedVoiceAgentId={setselectedVoiceAgentId} setopenDeleteModal={setopenDeleteModal}></TableItem>)
                    })}
                </tbody>
            </table>
        </div>
    )
}

const TableItem = ({ voiceAgent, setopenDeleteModal, setselectedVoiceAgentId, role }) => {
    const navigate = useNavigate();
    return (

        <tr className="bg-white border-b border-gray-400 text-center font-inter">
            <td className="px-6 py-4">
                {voiceAgent.name}
            </td>
            <td className="px-6 py-4">
                {voiceAgent.description}
            </td>
            <td className="px-6 py-4">
                {voiceAgent.workspace}
            </td>
            <td className="px-6 py-4">
                {voiceAgent.type}
            </td>
            <td className="px-6 py-4">
                {voiceAgent.priority === 0
                    ? "Low"
                    : voiceAgent.priority === 1
                        ? "Medium"
                        : "High"}
            </td>
            <td className="px-6 py-4">
                {voiceAgent.is_production === true ? "True" : "False"}
            </td>
            <td className="px-6 py-4">
                {voiceAgent.bot_max_cc}
            </td>

            <td className="px-6 py-4">
                {moment(voiceAgent.created_on).format("DD/MM/YYYY")}
            </td>
            <td className="px-6 py-4 " >

                {role === "administrator" &&
                    <div className="flex space-x-2 justify-center">
                        <PencilSquareIcon className="h-6 w-6 text-primary mr-4 inline align-bottom cursor-pointer" onClick={() => navigate("/create_voice_agent", { state: { id: voiceAgent._id } })} />
                        <TrashIcon className="text-red-600 h-6 w-6 mx-auto cursor-pointer" onClick={() => {
                            setopenDeleteModal(true);
                            setselectedVoiceAgentId(voiceAgent._id);
                        }} />
                    </div>
                }
            </td>
        </tr>

    )
}