import TextInput from "../../../components/inputs/TextInput";
import { useRef, useState } from "react";
import Layout from "../Layout";
import Loading from "../../../components/Loading";
import { create } from "../../../services/apis/organization";




export default function AddOrganization() {
    const [image, setImage] = useState('https://zradia.s3.us-east-2.amazonaws.com/organizations/5264206020');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null)
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleCheckboxChange = () => {
        setIsChecked(prev => !prev);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setImage(URL.createObjectURL(file));
        }
    };


    const handleOnCreate = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.target)
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        formData.set("environment", isChecked ? "production" : "development")
        const data = Object.fromEntries(formData.entries())


        console.log('dataa', data)
        setLoading(true)
        create(data).then((response) => {
            console.log('dataaaaa', response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log('error', error)
        })

    }


    if (loading) {
        return (
            <Layout title="Add Organization">
                <Loading />
            </Layout>
        )
    }


    return (

        <Layout title="Add Organization">
            <div className="rounded-lg border border-slate-200 bg-white shadow-sm p-8 mb-10 ">

                <h1 className="text-xl font-semibold text-secondary font-inter mb-2">
                    Create and Manage Organizations
                </h1>
                <p className="text-sm leading-6 text-gray-400 font-inter mb-4 w-3/4 mb-4">
                    Easily create new organization by adding organization name and admin email password. manage active workspaces and deactivate others.
                </p>

                <div className="w-[400px] relative border-2 border-gray-300 border-dashed rounded-lg p-6">
                    <div className="text-center">
                        <img className="w-full mx-auto max-h-20 object-contain transition-all duration-300 " src={image} alt="..." />
                        {image === "https://zradia.s3.us-east-2.amazonaws.com/organizations/5264206020" &&
                            <>
                                <h3 className="mt-2 text-sm font-medium text-gray-900">
                                    <label className="relative cursor-pointer">
                                        <span>organization logo</span>
                                        <span className="text-indigo-600 cursor-pointer" onClick={handleButtonClick}> browse </span>
                                        <span>to upload</span>
                                    </label>
                                </h3>

                                <p className="mt-1 text-xs text-gray-500">
                                    PNG, JPG, JPEG up to 10MB
                                </p>
                            </>
                        }
                    </div>
                    <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                </div>

                <div className="w-1/2 py-5">
                    <form className="" onSubmit={handleOnCreate}>
                        <TextInput type="text" id="name" name="name" placeholder="Name" required />
                        <TextInput type="text" id="username" name="email" placeholder="Username" required />
                        <TextInput type="password" id="password" name="password" placeholder="Password" required />



                        <hr className="my-5" />

                        <TextInput type="text" id="conversation_ai_platform" name="conversation_ai_platform" placeholder="Conversation AI Platform" required />
                        <div className="my-1 flex justify-between">
                            <label className="inline-flex items-center mb-5 cursor-pointer">
                                <p className="inline mr-3"><span className={`${isChecked && "text-primary"} font-normal m-1`}>Production</span>/<span className={`${!isChecked && "text-primary"} font-normal m-1`}>Development</span></p>
                                <input type="checkbox" name='environment' id="environment" checked={isChecked} onChange={handleCheckboxChange} className="shadow-sm w-4 h-4  border-gray-300 rounded focus:ring-primary focus:ring-2 font-inter sr-only peer" />
                                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-primary rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                            </label>
                        </div>
                        <button type="submit" className="bg-primary px-5 text-white font-semibold rounded-md  shadow-md transition duration-200 h-10 w-1/4  ">Create</button>


                    </form>



                </div>

            </div>
        </Layout>

    )
}


/* <div className="mr-20">
                        <div className=" border w-48 rounded-full h-48 p-4 flex justify-center items-center relative float-right ">
                            <img className="w-full h-full object-contain transition-all duration-300 " src={image} alt="..." />
                            <div onClick={handleButtonClick} className="cursor-pointer absolute inset-0 bg-black bg-opacity-25 opacity-100 hover:opacity-25 transition-all duration-300 flex items-center justify-center rounded-full">
                                <PhotoIcon className="text-white text-xl hover:text-gray-200 transition duration-200 w-10" />
                            </div>
                        </div>

                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                    </div> */