
import { useContext, useEffect, useMemo, useState } from "react";
import SelectInput from "../components/inputs/SelectInput";
import Layout from "../components/Layout";
import { getAllUseCasesAPI } from "../services/apis/useCases";
import { AuthContext } from "../store/auth_context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { formatDateDDMMYY } from "../utils/formatting";
import { constructQueryParams } from "../utils/encodeURI";
import Loading from "../components/Loading";
import { ReadSucceedcalls } from "../services/apis/calls";
import Table from "../components/tables/Table";
import NoData from "../components/NoData";

const types = [
    { text: "All", value: "All" },
    { text: "Execution", value: "Execution" },
    { text: "Testing", value: "Testing" }
]



export default function SucceedCalls() {
    const { profile } = useContext(AuthContext)
    const [calls, setCalls] = useState({ data: [], total: 0, count: 0 });
    const [voiceAgents, setVoiceAgents] = useState([])
    const [conversationStatuses, setConversationStatuses] = useState([])
    const [filters, setFilters] = useState({ voiceAgent: '', type: '', conversationStatus: '', gte: '', lte: '', page: 1, limit: 10 })
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false)

    const paginate = (page) => {
        setFilters((previous) => { return { ...previous, page: page } })
    }

    const limitation = (limit) => {
        setFilters((previous) => { return { ...previous, limit: limit } })
    }

    const handleOnDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setFilters((previous) => {
                return {
                    ...previous,
                    gte: formatDateDDMMYY(start),
                    lte: formatDateDDMMYY(end)
                }
            })
        }
    }


    const handleOnInputChange = (event) => {
        if (event.target.name === "voiceAgent") {
            const voiceAgent = voiceAgents.find((field) => field._id === event.target.value)
            if (voiceAgent.checkpoints && voiceAgent.checkpoints.length > 0) {
                setConversationStatuses(Array.from(new Map(voiceAgent.checkpoints.map(item => [item.status, { text: item.status, value: item.status }])).values()))
            }
        }

        setFilters((previous) => {
            return {
                ...previous,
                page: 1,
                [event.target.name]: event.target.value
            }
        })
    }

    const voiceAgentsOptions = useMemo(() => {
        if (voiceAgents.length > 0) {
            return voiceAgents.map((usecase) => {
                return { text: usecase.name, value: usecase._id }
            })
        }
        return []
    }, [voiceAgents]);

    useEffect(() => {
        getAllUseCasesAPI(profile.organization._id).then((response) => {
            if (response && response.data) {
                setVoiceAgents(response.data.usecases)
            }
        }).catch((error) => {
            console.log('error', error)
        })
    }, [profile.organization._id])


    useEffect(() => {
        setLoading(true)
        const query = constructQueryParams(filters);
        ReadSucceedcalls(profile.organization._id, query).then((response) => {
            setLoading(false)
            setCalls((previous) => {
                return ({
                    ...previous,
                    data: response.calls,
                    total: response.total,
                    count: response.count
                })
            })
            console.log('query', query)
            console.log('data', response)
        }).catch((error) => {
            setLoading(false)
        })
        console.log('query', query)
    }, [filters, profile.organization._id])

    if (loading) {
        return (
            <Layout title='Reports'>
                <Loading />
            </Layout>
        )
    }

    return (
        <Layout title='Succeed Calls'>

            <div className="flex space-x-4 justify-end ">
                <SelectInput title="Type" options={types} value={filters.type} onChange={handleOnInputChange} name="type" />
                <SelectInput title="Voice Agent" options={voiceAgentsOptions} value={filters.voiceAgent} onChange={handleOnInputChange} name="voiceAgent" />

                {filters.voiceAgent &&
                    <SelectInput title="Conversation Status" options={conversationStatuses} value={filters.conversationStatus} name="conversationStatus" onChange={handleOnInputChange} />
                }

                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    onChange={handleOnDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText="Date Range"
                    className="w-full h-11 px-4 py-2 mt-2 rounded-lg border border-gray-300 
                     focus:ring-1  focus:ring-primary focus:border-primary
                     shadow-sm bg-input placeholder-gray-400 
                     text-gray-900 focus:outline-none transition duration-300 ease-in-out" />
                <button className=" h-11 px-4 py-2 mt-2 rounded-lg shadow-md bg-primary text-white font-inter">Export</button>

            </div>

            {calls.data.length > 0 ?
                <Table data={calls} filters={filters} paginate={paginate} limitation={limitation} header={["lang", "callId", "trials", "callDuration", "created_on", "subStatus"]} questions={[]} />
                :
                <NoData />
            }
        </Layout>
    )
}